import React, { useState, useMemo, useEffect } from "react";
import { Modal, Box, Button } from "@mui/material";
import dayjs from "dayjs";
import LabelInput from "@components/LabelInput";
import LabelSelect from "@components/LabelSelect";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";
import { timeZoneList, timeFormatList, countries } from "@utils/userTimeZone";
import { validateEmail } from "@utils/valueRegex";

const UserCreateModal = ({ open, setOpen }) => {
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("Taiwan");
  const [language, setLanguage] = useState("en");
  const [timeZone, setTimeZone] = useState(
    "(UTC+07:00) Bangkok, Hanoi, Jakarta"
  );
  const [timeFormat, setTimeFormat] = useState("YYYY. MM. DD HH:mm");
  const [dubleClick, setDubleClick] = useState(true);

  useEffect(() => {
    setUserName("");
    setEmail("");
    setPhone("");
    setCountry("Taiwan");
    setLanguage("en");
    setTimeZone("(UTC+07:00) Bangkok, Hanoi, Jakarta");
    setTimeFormat("YYYY. MM. DD HH:mm");
  }, [open]);

  const closeFn = () => {
    setOpen(false);
  };

  const emailCheck = useMemo(() => {
    if (email.length === 0) {
      return null;
    }

    return validateEmail(email);
  }, [email]);

  const button_disabled = useMemo(() => {
    if (
      username.length > 0 &&
      email.length > 0 &&
      phone.length > 0 &&
      country.length > 0 &&
      language.length > 0 &&
      timeZone.length > 0 &&
      timeFormat.length > 0 &&
      !emailCheck
    ) {
      return false;
    }

    return true;
  }, [
    username,
    email,
    phone,
    country,
    language,
    timeZone,
    timeFormat,
    emailCheck,
  ]);

  const createUser = async () => {
    try {
      const requestCreateUser = await userPermAxios.post("/create-user", {
        username,
        email,
        phone,
        country,
        language,
        timeZone,
        timeFormat,
      });

      const { success } = requestCreateUser;

      if (success) {
        return closeFn();
      }
    } catch (err) {
      let message = "";

      if (err.response && err?.response?.data?.message) {
        message = err?.response?.data?.message;
      }

      create_alert_fn("warning", message, () => {});
    } finally {
      setDubleClick(true);
    }
  };

  const openAlert = () => {
    if (!dubleClick) return;
    setDubleClick(false);

    create_alert_fn(
      "info",
      "Are you sure you want to create a user?",
      async () => {
        await createUser();
      }
    );
  };

  return (
    <Modal
      open={open}
      onClose={closeFn}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="create-user-modal-total-body">
          <div className="create-user-modal-add-input-box">
            <LabelInput
              label="User Name"
              value={username}
              setValue={setUserName}
            />
            <LabelInput
              label="Email"
              value={email}
              setValue={setEmail}
              error={emailCheck === null ? false : true}
              helperText={emailCheck}
            />
            <LabelInput label="Phone" value={phone} setValue={setPhone} />
            <LabelSelect
              label="Country"
              value={country}
              setValue={setCountry}
              list={countries.map((c) => ({ name: c.label, value: c.label }))}
            />
            <LabelSelect
              label="Language"
              value={language}
              setValue={setLanguage}
              list={[
                { value: "en", name: "English" },
                { value: "th", name: "เกาหลี" },
              ]}
            />
            <LabelSelect
              label="Time Format"
              value={timeZone}
              setValue={setTimeZone}
              list={timeZoneList.map((c) => ({ name: c.name, value: c.name }))}
            />
            <LabelSelect
              label="Date Format"
              value={timeFormat}
              setValue={setTimeFormat}
              list={timeFormatList}
              helperText={
                !timeFormat && timeFormat === ""
                  ? null
                  : `ex) ${dayjs().format(timeFormat)}`
              }
            />
          </div>
          <div className="create-user-modal-control-box">
            <Button
              onClick={openAlert}
              disabled={button_disabled}
              className="create-user-modal-submit-btn"
            >
              Submit
            </Button>
            <Button
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              onClick={closeFn}
              className="create-user-modal-cancle-btn"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default UserCreateModal;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "16px",
  border: "1px solid var(--Gray-200, #E4E7EC)",
  background: "var(--White, #FFF)",
  boxShadow:
    "0px 8px 16px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)",
};
