import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "./layout.scss";

const Layout = ({ top_menu, menu, children }) => {
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  return (
    <div className="layout-total-body">
      <Header />
      <Sidebar
        top_menu={top_menu}
        menu={menu}
        isSidebarVisible={isSidebarVisible}
        setSidebarVisible={setSidebarVisible}
      />
      <main className={`layout-main-body ${isSidebarVisible ? "active" : ""}`}>
        {children}
      </main>
    </div>
  );
};

export default Layout;
