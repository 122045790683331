import React, { useMemo } from "react";
import { useParams, Navigate } from "react-router-dom";
import Layout from "@layout/Layout";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { UserSetting } from "@/atoms/userInfo";
import {
  DataConsolidationModalStatus,
  ReleaseDataConsolidationModalStatus,
  RequestDataConsolidationModalStatus,
  QtyControlDataConsolidationModalStatus,
  ContactPointStatus,
  JobNumberStatus,
} from "@atoms/modalStatus";
import RequestDataConsolidationModal from "@/components/requestDataConsolidationModal/RequestDataConsolidationModal";
import DataConsolidationModal from "@components/dataConsolidationModal/DataConsolidationModal";
import ReleaseDataConsolidationModal from "@/components/releaseDataConsolidationModal/ReleaseDataConsolidationModal";
import ContactPointModal from "@/components/ContactPointModal/ContactPointModal";
import JobNumberSelectModal from "@/components/jobNumberSelectModal/JobNumberSelectModal";
import MaterialMenu from "./menu/MaterialMenu";
import MaterialInven from "./menu/MaterialInven";
import MaterialMaster from "./menu/MaterialMaster";
import MaterialJobNum from "./menu/MaterialJobNum";

import { isLoginCheck } from "@/utils/isLoginCheck";
import QtyControlDataConsolidationModal from "@/components/qtyControlDataConsolidationModal/QtyControlDataConsolidationModal";

const HomePage = () => {
  const userSetting = useRecoilValue(UserSetting);

  const [
    dataConsolidationModalStatus,
    releaseDataConsolidationModalStatus,
    requestDataConsolidationModalStatus,
    qtyControlDataConsolidationModalStatus,
    contactPointStatus,
    jobNumberStatus,
  ] = [
    useRecoilValue(DataConsolidationModalStatus),
    useRecoilValue(ReleaseDataConsolidationModalStatus),
    useRecoilValue(RequestDataConsolidationModalStatus),
    useRecoilValue(QtyControlDataConsolidationModalStatus),
    useRecoilValue(ContactPointStatus),
    useRecoilValue(JobNumberStatus),
  ];

  // const dataConsolidationModalStatus = useRecoilValue(
  //   DataConsolidationModalStatus
  // );
  // const releaseDataConsolidationModalStatus = useRecoilValue(
  //   ReleaseDataConsolidationModalStatus
  // );
  // const requestDataConsolidationModalStatus = useRecoilValue(
  //   RequestDataConsolidationModalStatus
  // );

  const { menu } = useParams();

  const query_login_check = useQuery(
    ["user_login_check", menu],
    async () => await isLoginCheck()
  );

  const { data, isLoading } = query_login_check;

  const renderComponents = useMemo(() => {
    if (isLoading) {
      return null;
    }

    if (menu === "material_inventory") {
      return <MaterialInven />;
    }

    if (menu === "material_master") {
      return <MaterialMaster />;
    }

    if (menu === "job_number") {
      return <MaterialJobNum />;
    }

    if (material_menu_list.includes(menu)) {
      return <MaterialMenu />;
    }

    return null;
  }, [menu, isLoading]);

  if (!isLoading) {
    const { isLoggedIn } = data;

    if (!isLoggedIn) {
      return <Navigate to="/login" />;
    }

    const adminUser = userSetting?.admin_user;
    const userMenu = userSetting?.menu ?? [];

    if (!adminUser) {
      if (!userMenu.includes(menu)) {
        return <Navigate to="/home" />;
      }
    }
  }

  return (
    <>
      <Layout top_menu="home" menu={menu}>
        {renderComponents}
      </Layout>
      {dataConsolidationModalStatus.open && <DataConsolidationModal />}
      {releaseDataConsolidationModalStatus.open && (
        <ReleaseDataConsolidationModal />
      )}
      {requestDataConsolidationModalStatus.open && (
        <RequestDataConsolidationModal />
      )}
      {qtyControlDataConsolidationModalStatus.open && (
        <QtyControlDataConsolidationModal />
      )}
      {contactPointStatus.open && <ContactPointModal />}
      {jobNumberStatus.open && <JobNumberSelectModal />}
    </>
  );
};

export default HomePage;

const material_menu_list = [
  "sales",
  "job_number",
  "material_master",
  "customer",
  "vendor",
  "purchase_request",
  "request_for_quotation",
  "purchase_order",
  "packing_list",
  "material_receive",
  "material_release",
  "product_export",
  "inventory_quantity_control",
];
