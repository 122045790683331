import React from "react";
import { InputLabel, TextField } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { labelInputTheme } from "@styles/muiThemes";
import "@styles/components.scss";

const LabelInput = ({
  label,
  value,
  setValue,
  helperText,
  placeholder,
  error,
  disabled = false,
}) => {
  return (
    <div className="label-input-box">
      <ThemeProvider theme={labelInputTheme}>
        <InputLabel
          htmlFor={`label-input-${label}`}
          className="label-input-title"
        >
          {label}
        </InputLabel>
        <TextField
          id={`label-input-${label}`}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          disabled={disabled}
        />
      </ThemeProvider>
    </div>
  );
};

export default LabelInput;
