import React from "react";

const DeleteIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.3292 9.2C8.73473 9.2 9.06347 9.5134 9.06347 9.9V18.3C9.06347 18.6866 8.73473 19 8.3292 19C7.92368 19 7.59494 18.6866 7.59494 18.3V9.9C7.59494 9.5134 7.92368 9.2 8.3292 9.2Z"
        fill="#344054"
      />
      <path
        d="M12.0005 9.2C12.4061 9.2 12.7348 9.5134 12.7348 9.9V18.3C12.7348 18.6866 12.4061 19 12.0005 19C11.595 19 11.2663 18.6866 11.2663 18.3V9.9C11.2663 9.5134 11.595 9.2 12.0005 9.2Z"
        fill="#344054"
      />
      <path
        d="M16.4061 9.9C16.4061 9.5134 16.0774 9.2 15.6719 9.2C15.2663 9.2 14.9376 9.5134 14.9376 9.9V18.3C14.9376 18.6866 15.2663 19 15.6719 19C16.0774 19 16.4061 18.6866 16.4061 18.3V9.9Z"
        fill="#344054"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.546 5.7C21.546 6.4732 20.8885 7.1 20.0775 7.1H19.3432V19.7C19.3432 21.2464 18.0282 22.5 16.4061 22.5H7.59494C5.97284 22.5 4.65788 21.2464 4.65788 19.7V7.1H3.92361C3.11256 7.1 2.45508 6.4732 2.45508 5.7V4.3C2.45508 3.5268 3.11256 2.9 3.92361 2.9H9.06347C9.06347 2.1268 9.72096 1.5 10.532 1.5H13.4691C14.2801 1.5 14.9376 2.1268 14.9376 2.9H20.0775C20.8885 2.9 21.546 3.5268 21.546 4.3V5.7ZM6.29974 7.1L6.12641 7.18262V19.7C6.12641 20.4732 6.78389 21.1 7.59494 21.1H16.4061C17.2172 21.1 17.8747 20.4732 17.8747 19.7V7.18262L17.7013 7.1H6.29974ZM3.92361 5.7V4.3H20.0775V5.7H3.92361Z"
        fill="#344054"
      />
    </svg>
  );
};

export default DeleteIcon;
