import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { InputLabel, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { labelInputTheme } from "@styles/muiThemes";
import "@styles/components.scss";

const PwInput = ({
  label,
  value,
  setValue,
  placeholder,
  helperText,
  findPassUse,
  error,
  onKeyDown = () => {},
}) => {
  const navigate = useNavigate();
  const [showPW, setShowPW] = useState(false);

  const toggleShowPW = (e) => {
    e.preventDefault();
    setShowPW((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="label-input-box">
      <ThemeProvider theme={labelInputTheme}>
        <InputLabel htmlFor="pw-input" className="label-input-title">
          {label}
        </InputLabel>
        <TextField
          id="pw-input"
          type={showPW ? "text" : "password"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowPW}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPW ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={error === true}
          helperText={helperText ?? ""}
        />
      </ThemeProvider>
      {findPassUse && (
        <div
          className="input-bottom-text"
          onClick={() => {
            navigate("/forget-password");
          }}
        >
          Forgot Password?
        </div>
      )}
    </div>
  );
};

export default PwInput;
