import React from "react";
import PwInput from "@components/PwInput";
import CustomCheckBox from "@components/CustomCheckBox";
import "./securitySetting.scss";

const SecuritySetting = ({
  currentPW,
  setCurrentPW,
  newPW,
  setNewPW,
  repeatNewPW,
  setRepeatNewPW,
  checkValue,
  setCheckValue,
  newPWCheck,
  repeatPwCheck,
}) => {
  return (
    <div className="security-setting-total-body">
      <div className="security-setting-input-box">
        <PwInput
          label="Current Password"
          value={currentPW}
          setValue={setCurrentPW}
          placeholder=""
        />
        <PwInput
          label="New Password"
          value={newPW}
          setValue={setNewPW}
          placeholder=""
          error={newPWCheck === null ? false : true}
          helperText={newPWCheck}
        />
        <PwInput
          label="Confirm Password"
          value={repeatNewPW}
          setValue={setRepeatNewPW}
          placeholder=""
          error={repeatPwCheck === null ? false : true}
          helperText={repeatPwCheck}
        />
      </div>
      <div className="security-setting-input-bottom">
        <CustomCheckBox
          checked={checkValue}
          onChange={() => setCheckValue((prev) => !prev)}
        />

        <div className="includes-link-text-box">
          <div className="normal-text" style={{ color: "#344054" }}>
            I Agree to our{" "}
          </div>
          <div className="under-line-text">Terms of use</div>
          <div className="normal-text" style={{ color: "#344054" }}>
            and
          </div>
          <div className="under-line-text">Privacy Policy</div>
        </div>
      </div>
    </div>
  );
};

export default SecuritySetting;
