import React, { useMemo } from "react";
import { Navigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import ProjectAddUserModal from "@/components/projectAddUserModal/ProjectAddUserModal";
import MenuAdduserModal from "@/components/menuAdduserModal/MenuAdduserModal";
import UserAddPermModal from "@/components/userAddPermModal/UserAddPermModal";
import Layout from "@layout/Layout";
import UserPermPage from "./menu/UserPermPage";
import LogPage from "./menu/LogPage";
import { userPermAxios } from "@/utils/customAxios";
import { isLoginCheck } from "@/utils/isLoginCheck";
import { create_alert_fn } from "@/utils/createAlert";
import TaxReportPage from "./menu/TaxReportPage";

const AdminRouter = () => {
  const { menu } = useParams();

  const query_login_check = useQuery(["user_login_check", menu], async () => {
    await isLoginCheck();

    const requserCheckAdminUser = await userPermAxios.get("/check-admin-user");

    const { data } = requserCheckAdminUser;

    return data;
  });

  const { data = true, isLoading } = query_login_check;

  const renderComponents = useMemo(() => {
    if (isLoading) {
      return null;
    }

    if (user_permission_menu_list.includes(menu)) {
      return <UserPermPage />;
    }

    if (logs_menu_list.includes(menu)) {
      return <LogPage />;
    }

    if (menu === "tax_report") {
      return <TaxReportPage />;
    }

    return null;
  }, [menu, isLoading]);

  if (!data) {
    create_alert_fn(
      "warning",
      "You do not have access to the Administrator page.",
      () => {}
    );
    return <Navigate to="/home" />;
  }

  return (
    <>
      <Layout top_menu="admin" menu={menu}>
        {renderComponents}
      </Layout>
      <ProjectAddUserModal />
      <MenuAdduserModal />
      <UserAddPermModal />
    </>
  );
};

export default AdminRouter;

const user_permission_menu_list = [
  "user_registration",
  "assign_user_to_bps",
  "assign_user_to_project",
];

const logs_menu_list = ["login_log", "record_log"];
