import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useCallback,
} from "react";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";
import "./activityLogTable.scss";

const ActivityLogTable = ({ rows, columns }) => {
  return (
    <div className="activity-log-table-total-body">
      <DataGridPro
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        rowHeight={40}
        columnHeaderHeight={40}
        sx={{
          ...materialTableStyle,
          ...TableScrollbarStyle,
        }}
        hideFooter
        disableColumnPinning
        disableColumnSelector
        disableDensitySelector
      />
    </div>
  );
};

export default ActivityLogTable;
