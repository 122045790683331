import React, { useState, useRef, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { TargetComment } from "@atoms/commnet";
import { UserTargetProject, UserSetting } from "@atoms/userInfo";
import { useQuery } from "react-query";
import _ from "lodash";
import { IconButton } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import JobNumberTable from "@/components/JobNumberTable/JobNumberTable";
import UpdateComment from "@components/comment/UpdateComment";
import IconInput from "@/components/IconInput";
import AddJobNumberModal from "@/components/JobNumberTable/AddJobNumberModal";
import useRefreshReactQuery from "@hooks/useRefreshReactQuery";
import { groupColorList } from "@utils/colorList";
import {
  createTableColumn,
  materialTablePinnedColumn,
  materialTableSearchItems,
} from "@utils/tableInitialData";
import { userPermAxios } from "@utils/customAxios";

import "./menu.scss";

const MaterialJobNum = () => {
  const { menu } = useParams();
  const userTargetProject = useRecoilValue(UserTargetProject);
  const userSetting = useRecoilValue(UserSetting);

  const admin_user = userSetting?.admin_user;
  const approver = userSetting?.approver;

  const [targetComment, setTargetComment] = useRecoilState(TargetComment);
  const [tableRefs, setTableRefs] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [groupRow, setGroupRow] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [addModalToggle, setAddModalToggle] = useState(false);
  const webSocket = useRef(null);

  const query_key = useMemo(
    () => ["table_data", menu, userTargetProject],
    [menu, userTargetProject]
  );

  const [refresh] = useRefreshReactQuery(query_key);

  const query_table_data = useQuery(
    query_key,
    () => query_fetch_data(menu, userTargetProject),
    {
      initialData: {
        rows: null,
        group_rows: {},
        lineitem_use: false,
        user_perm: null,
      },
      onSuccess: (data) => {
        setIsLoading(false);
        setGroupRow(data.group_rows);
      },
    }
  );

  const { user_perm } = query_table_data.data;

  useEffect(() => {
    setGroupRow({});

    setTableRefs(null);
    setTargetComment(null);

    setIsLoading(true);
    setAddModalToggle(false);
    // eslint-disable-next-line
  }, [menu]);

  const reFreshTable = () => {
    setGroupRow({});

    setTableRefs(null);
    setTargetComment(null);

    setIsLoading(true);
    refresh();
  };

  useEffect(() => {
    if (Object.keys(groupRow).length > 0) {
      const newRefs = {};

      Object.keys(groupRow).forEach((c) => {
        newRefs[c] = React.createRef();
      });

      setTableRefs(newRefs);
    }
  }, [groupRow, menu]);

  useEffect(() => {
    webSocket.current = new WebSocket(
      `${process.env.NODE_ENV === "development" ? "ws" : "wss"}://${
        process.env.REACT_APP_SOKET_URI
      }`
    );

    if (webSocket) {
      webSocket.current.onopen = () => {
        console.log("WebSocket 연결!");
      };

      webSocket.current.onclose = (error) => {};

      webSocket.current.onerror = (error) => {
        console.log("WebSocket 실패!", error);
      };

      webSocket.current.onmessage = (event) => {
        const { data } = event;

        const dataParse = JSON.parse(data);

        const { action, tablename } = dataParse;

        const { group_name, addRow } = dataParse.data;

        if (menu === tablename) {
          if (action === "delete-row" || action === "update-cell-group") {
            reFreshTable();
          }

          if (action === "new-material" && tablename === "job_number") {
            const job_number_api = tableRefs?.["Job Number"]?.current?.apiRef;
            if (job_number_api) {
              job_number_api.updateRows([{ ...addRow }]);
            }
          }
        }
      };
      return () => {
        webSocket.current?.close();
      };
    }
    // eslint-disable-next-line
  }, [webSocket, tableRefs, refresh]);

  const columns = useMemo(() => {
    const statusPerm = admin_user ? true : approver ? true : false;

    const table_column = createTableColumn(
      menu,
      query_key,
      statusPerm,
      admin_user,
      user_perm
    );

    return table_column;
  }, [menu, query_key, admin_user, user_perm, approver]);

  const upperTablePinnedColumns = useMemo(() => {
    return materialTablePinnedColumn(menu);
  }, [menu]);

  const tableGroupHeight = "calc(100vh - 104px - 48px - 8px - 16px)";

  const filterItems = useMemo(() => {
    return materialTableSearchItems(menu, searchValue);
  }, [menu, searchValue]);

  const addBtnPerm = useMemo(() => {
    if (admin_user || user_perm === "edit") {
      return true;
    }

    return false;
  }, [admin_user, userSetting]);

  if (isLoading) {
    return null;
  }

  return (
    <div className="memu-total-body">
      <div className="menu-button-box">
        <div className="menu-control-botton-group">
          <IconInput
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search Here"
            rightIcon={
              searchValue.length > 0 ? (
                <IconButton onClick={() => setSearchValue("")}>
                  <CloseOutlinedIcon fontSize="small" />
                </IconButton>
              ) : null
            }
          />
        </div>
        <div className="menu-button-box-vertical-bar" />
        {addBtnPerm && (
          <IconButton
            className="menu-new-group-botton"
            onClick={() => setAddModalToggle((prev) => !prev)}
          >
            <AddOutlinedIcon sx={{ width: "20px", height: "20px" }} />
            Add New Job Number Code
          </IconButton>
        )}

        <IconButton className="menu-new-group-botton" onClick={reFreshTable}>
          <RefreshOutlinedIcon sx={{ width: "20px", height: "20px" }} />
        </IconButton>
      </div>

      <div
        className="layout-table-group-box"
        style={{ height: tableGroupHeight }}
      >
        {groupRow &&
          Object.keys(tableRefs ?? {}).length > 0 &&
          _.sortBy(Object.keys(groupRow ?? {})).map((c, i) => {
            const colorIndex = i % groupColorList.length;

            return (
              <JobNumberTable
                key={c}
                table_name={menu}
                rows={groupRow?.[c] ?? []}
                columns={columns.map((c2) => ({
                  ...c2,
                  table_group_name: c,
                }))}
                isLoading={isLoading}
                group_name={c}
                group_color={groupColorList[colorIndex]}
                upperTablePinnedColumns={upperTablePinnedColumns}
                filter_items={filterItems}
                ref={tableRefs[c]}
              />
            );
          })}
      </div>

      {addModalToggle && (
        <AddJobNumberModal
          open={addModalToggle}
          setAddModalToggle={setAddModalToggle}
          refresh_fn={reFreshTable}
        />
      )}
    </div>
  );
};

export default MaterialJobNum;

const query_fetch_data = async (table_name, user_project) => {
  const result = {
    rows: null,
    group_rows: {},
    lineitem_use: false,
    user_perm: null,
  };

  if (!user_project) {
    return result;
  }

  try {
    const requestRowsData = await userPermAxios.get(
      `/${table_name}/${user_project?.project_code}/rows`
    );

    const { data, lineitem_use } = requestRowsData;

    const data_group = _.groupBy(data, "group_name");

    const requestUserPermStatus = await userPermAxios.get(
      `/${table_name}/get-user-menu-perm-status`
    );

    const { permStatus } = requestUserPermStatus;

    result.group_rows = data_group;
    result.lineitem_use = lineitem_use;
    result.user_perm = permStatus;
  } catch (err) {
  } finally {
    return result;
  }
};
