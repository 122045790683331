import React, { useMemo } from "react";

import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import {
  deleteMultiRowData,
  copyMultiRowData,
  AdvanceMultiRowData,
} from "@utils/adminTableCellFn";
import { groupColorList } from "@utils/colorList";
import {
  tableStatusField,
  tableLockStatusField,
} from "@utils/tableInitialData";
import { create_alert_fn } from "@/utils/createAlert";
import "@styles/components.scss";

const SelectRowControlMenu = ({
  group_list,
  table_name,
  totalCount,
  selectionModel,
  setSelectionModel,
  refs,
  columns,
  table_rows,
  admin_user,
  user_perm,
}) => {
  const select_group_color_list = useMemo(() => {
    const total_group_list = group_list.map((c, i) => {
      const colorIndex = i % groupColorList.length;

      return { group_name: c, color: groupColorList[colorIndex] };
    });

    const selection_model_list = Object.keys(selectionModel).map((c) => {
      const find_group_color = total_group_list.find(
        (c2) => c2.group_name === c
      );

      return {
        group_name: c,
        color: find_group_color?.color,
        selection_model: selectionModel?.[c] ?? [],
      };
    });

    return selection_model_list.filter((c) => c.selection_model.length > 0);
  }, [group_list]);

  const closeSelectMenu = () => {
    setSelectionModel(null);
  };

  const deleteRows = async () => {
    const result = {
      type: "warning",
      message: "",
      fn: () => {
        closeSelectMenu();
      },
    };

    try {
      const targetRowList = Object.values(selectionModel).flat(1);

      const requestMultiDelete = await deleteMultiRowData(
        table_name,
        targetRowList
      );

      const { success, message } = requestMultiDelete;

      if (success) {
        result.type = "success";
        result.message = message;
      }
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        result.message = err?.response?.data?.message;
      }
    } finally {
      create_alert_fn(result.type, result.message, result.fn);
    }
  };

  const copyRows = async () => {
    const result = {
      type: "warning",
      message: "",
      fn: () => {
        closeSelectMenu();
      },
    };
    try {
      const targetRowList = Object.values(selectionModel).flat(1);

      const requestMultiCopy = await copyMultiRowData(
        table_name,
        targetRowList
      );

      const { success, message } = requestMultiCopy;

      if (success) {
        result.type = "success";
        result.message = message;
      }
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        result.message = err?.response?.data?.message;
      }
    } finally {
      create_alert_fn(result.type, result.message, result.fn);
    }
  };

  const advanceRows = async () => {
    const result = {
      type: "warning",
      message: "",
      fn: () => {
        closeSelectMenu();
      },
    };

    try {
      const targetRowList = Object.values(selectionModel).flat(1);

      const requestMultiCopy = await AdvanceMultiRowData(
        table_name,
        targetRowList
      );

      const { success, message } = requestMultiCopy;

      if (success) {
        result.type = "success";
        result.message = message;
      }
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        result.message = err?.response?.data?.message;
      }
    } finally {
      create_alert_fn(result.type, result.message, result.fn);
    }
  };

  const copyBtnDisabled = useMemo(() => {
    let result = false;

    if (user_perm === "read") {
      result = true;
    }

    if (admin_user) {
      result = false;
    }

    return result;
  }, [admin_user, user_perm]);

  const deleteBtnDisabled = useMemo(() => {
    const selectionIds = Object.values(selectionModel).flat();

    const targetMemuStatus = tableLockStatusField(table_name);

    let result = false;

    if (user_perm === "read") {
      result = true;
    }

    if (admin_user) {
      result = false;
    }

    for (const id of selectionIds) {
      const targetRow = table_rows.find((c) => c.id === id);

      const targetRowStatus = targetRow?.[targetMemuStatus?.status_field];

      if (targetMemuStatus.lock_status_list.includes(targetRowStatus)) {
        result = true;

        break;
      }
    }

    return result;
  }, [table_rows, selectionModel, admin_user, user_perm]);

  const advanceBtnVisible = useMemo(() => {
    const visibleArr = [
      "request_for_quotation",
      "purchase_order",
      "packing_list",
    ];

    return visibleArr.includes(table_name);
  }, [table_name]);

  const advanceBtnDisabled = useMemo(() => {
    const selectionIds = Object.values(selectionModel).flat();

    const targetMemuStatus = tableLockStatusField(table_name);

    let result = false;

    if (user_perm === "read") {
      result = true;
    }

    if (admin_user) {
      result = false;
    }

    for (const id of selectionIds) {
      const targetRow = table_rows.find((c) => c.id === id);

      const targetRowStatus = targetRow?.[targetMemuStatus?.status_field];

      if (
        !targetMemuStatus.lock_status_list.includes(targetRowStatus) ||
        (targetRow?.bid_sortation !== undefined &&
          targetRow?.bid_sortation === "For Bid")
      ) {
        result = true;

        break;
      }
    }

    return result;
  }, [table_rows, selectionModel, admin_user, user_perm]);

  return (
    <div className="select-row-control-menu-body">
      <div className="select-row-control-menu-left">{totalCount}</div>
      <div className="select-row-control-menu-center">
        <div className="select-row-control-menu-center-left">
          <div className="select-row-control-menu-select-group-title">
            Selected Item
          </div>
          <div className="select-row-control-menu-select-group-icons">
            {select_group_color_list.map((c) => {
              const { selection_model, color } = c;
              return selection_model.map((c2) => {
                return (
                  <div
                    key={c2}
                    className="select-row-control-menu-select-group-icon"
                    style={{ backgroundColor: color }}
                  />
                );
              });
            })}
          </div>
        </div>
        <div className="select-row-control-menu-center-right">
          <IconButton
            className="detail-control-menu-icon-btn"
            sx={
              {
                // width: "64px",
                // height: "64px",
                // display: "flex",
                // flexDirection: "column",
                // gap: "5px",
                // fontSize: "14px",
              }
            }
            onClick={copyRows}
            disabled={copyBtnDisabled}
          >
            <FileCopyOutlinedIcon
              className={`detail-control-menu-icon ${
                copyBtnDisabled && "disabled"
              }`}
            />
            <div
              className={`detail-control-menu-icon-name ${
                copyBtnDisabled && "disabled"
              }`}
            >
              Duplicate
            </div>
          </IconButton>

          <IconButton
            className="detail-control-menu-icon-btn"
            disabled={deleteBtnDisabled}
            onClick={deleteRows}
          >
            <DeleteForeverOutlinedIcon
              className={`detail-control-menu-icon ${
                deleteBtnDisabled && "disabled"
              }`}
            />
            <div
              className={`detail-control-menu-icon-name ${
                deleteBtnDisabled && "disabled"
              }`}
            >
              Delete
            </div>
          </IconButton>

          {advanceBtnVisible && (
            <IconButton
              className="detail-control-menu-icon-btn"
              disabled={advanceBtnDisabled}
              onClick={advanceRows}
            >
              <ReplyOutlinedIcon
                className={`detail-control-menu-icon ${
                  advanceBtnDisabled && "disabled"
                }`}
                sx={{
                  transform: "scaleX(-1)",
                }}
              />
              <div
                className={`detail-control-menu-icon-name ${
                  advanceBtnDisabled && "disabled"
                }`}
              >
                Advance
              </div>
            </IconButton>
          )}
        </div>
      </div>
      <div className="select-row-control-menu-right" onClick={closeSelectMenu}>
        <IconButton sx={{ width: "63px", height: "63px" }}>
          <CloseOutlinedIcon fontSize="medium" />
        </IconButton>
      </div>
    </div>
  );
};

export default SelectRowControlMenu;
