import React from "react";

const CommentIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.825 15.5C11.4384 15.5 11.125 15.1866 11.125 14.8V12.875H9.2C8.8134 12.875 8.5 12.5616 8.5 12.175V11.825C8.5 11.4384 8.8134 11.125 9.2 11.125H11.125V9.2C11.125 8.8134 11.4384 8.5 11.825 8.5H12.175C12.5616 8.5 12.875 8.8134 12.875 9.2V11.125H14.8C15.1866 11.125 15.5 11.4384 15.5 11.825V12.175C15.5 12.5616 15.1866 12.875 14.8 12.875H12.875V14.8C12.875 15.1866 12.5616 15.5 12.175 15.5H11.825Z"
        fill="#344054"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.025 21.975L6.75 21.0951C8.34561 22.0179 10.1568 22.5025 12 22.5C17.7991 22.5 22.5 17.7991 22.5 12C22.5 6.20085 17.7991 1.5 12 1.5C6.20085 1.5 1.5 6.20085 1.5 12C1.5 13.912 2.01135 15.7065 2.9049 17.25L2.025 21.975ZM12 20.75L11.9976 20.75C10.4628 20.7521 8.95471 20.3485 7.6261 19.5802C7.26457 19.3711 6.84019 19.2982 6.42962 19.3747L4.21243 19.7876L4.62532 17.5704C4.70183 17.1595 4.62879 16.7349 4.41942 16.3732C3.67621 15.0894 3.25 13.5964 3.25 12C3.25 7.16735 7.16735 3.25 12 3.25C16.8327 3.25 20.75 7.16735 20.75 12C20.75 16.8326 16.8327 20.75 12 20.75Z"
        fill="#344054"
      />
    </svg>
  );
};

export default CommentIcon;
