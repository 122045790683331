import React from "react";
import { Button } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { defaultButtonTheme } from "@styles/muiThemes";

const DefaultButton = ({ label, disabled, onClick, width, height }) => {
  return (
    <ThemeProvider theme={defaultButtonTheme}>
      <Button
        variant="outlined"
        disabled={disabled}
        sx={{ textTransform: "none", width, height }}
        onClick={onClick}
      >
        {label}
      </Button>
    </ThemeProvider>
  );
};

export default DefaultButton;
