import React from "react"; // , { useState }
import { useQuery } from "react-query";
import EditorForm from "./EditorForm";
import CommentCard from "./CommentCard";
import useRefreshReactQuery from "@hooks/useRefreshReactQuery";
import { userPermAxios } from "@utils/customAxios";

const CommentSheet = ({ targetComment, tableHeight }) => {
  const { parent_table_name, table_name, id, query_key } = targetComment ?? {};

  const [table_refresh] = useRefreshReactQuery(query_key);
  // const [isDataLoading, setIsDataLoading] = useState(true);
  const query_comment_data = useQuery(
    ["comment_data", parent_table_name, table_name, id],
    () => query_fetch_data(parent_table_name, table_name, id),
    {
      initialData: { comments: [] },
      onSuccess: () => {
        // setIsDataLoading(false);
      },
    }
  );

  const { comments } = query_comment_data.data;

  return (
    <div
      className="comment-sheet-total-body"
      style={{ height: tableHeight, overflow: "auto" }}
    >
      <EditorForm
        targetComment={targetComment}
        type="create"
        table_refresh_fn={table_refresh}
      />
      <div className="comment-sheet-list-box">
        {comments.map((c) => {
          return (
            <CommentCard
              data={c}
              key={c.id}
              targetComment={targetComment}
              table_refresh_fn={table_refresh}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CommentSheet;

const query_fetch_data = async (parent_table_name, table_name, id) => {
  let result = { comments: [] };

  try {
    const requestCommentList = await userPermAxios.post("/get-comments", {
      tableName: table_name,
      rowId: id,
    });

    const { data } = requestCommentList;

    result.comments = data;
  } catch (err) {
  } finally {
    return result;
  }
};
