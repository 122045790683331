import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import * as am5 from "@amcharts/amcharts5";
import LoginPage from "@pages/login/LoginPage";
import ForgetPWPage from "@pages/login/ForgetPWPage";
import ResetPWPage from "@pages/login/ResetPWPage";
import HomePage from "@pages/home/home";
import HomeRedirect from "./pages/home/HomeRedirect";
import AdminRouter from "./pages/admin/AdminRouter";

import { history } from "@utils/history";
import { getUserGeneralMenu, isLoginCheck } from "./utils/isLoginCheck";

import "@styles/public.scss";

am5.addLicense("AM5C363108325");
am5.addLicense("AM5M363108325");

const Router = () => {
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const { isLoggedIn } = await isLoginCheck();
  //     if (isLoggedIn) {
  //       await getUserGeneralMenu();
  //     }
  //   };

  //   fetchData();
  // }, []);

  // useAxiosInterceptors();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forget-password" element={<ForgetPWPage />} />
        <Route path="/new-password" element={<ResetPWPage />} />
        <Route path="/home" element={<HomeRedirect />} />
        <Route path="/home/:menu" element={<HomePage />} />
        <Route
          path="/admin"
          element={<Navigate to="/admin/user_registration" />}
        />
        <Route path="/admin/:menu" element={<AdminRouter />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
