import React from "react";
import { Button, IconButton } from "@mui/material";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

const FileDownloadBtn = ({
  url,
  filename,
  delete_fn_use = false,
  delete_fn = () => {},
}) => {
  return (
    <div>
      <a
        href={url}
        download={filename}
        target="_blank"
        rel="noreferrer"
        style={{
          textDecoration: "none",
          color: "#1976d2",
        }}
      >
        <Button
          sx={{ fontSize: 14, textTransform: "none" }}
          variant="outlined"
          startIcon={<AttachFileOutlinedIcon fontSize="small" />}
        >
          {filename}
        </Button>
      </a>
      {delete_fn_use && (
        <IconButton onClick={delete_fn}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )}
    </div>
  );
};

export default FileDownloadBtn;
