import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { userPermAxios } from "@utils/customAxios";
import { createLoginActivityLogColumn } from "@/components/activityLogTable/Column";
import "./menu.scss";
import ActivityLogTable from "@/components/activityLogTable/ActivityLogTable";

const LogPage = () => {
  const { menu } = useParams();

  const query_admin_log_data = useQuery(
    ["admin_log_data", menu],
    async () => {
      return await query_fetch_data(menu);
    },
    {
      initialData: { log_data: [] },
    }
  );

  const { log_data } = query_admin_log_data.data;

  const table_info = useMemo(() => {
    if (menu === "login_log") {
      return {
        table_name: "login_activities",
        title: "Login Activity Logs",
        columns: [],
        lineDataUse: false,
      };
    }

    if (menu === "record_log") {
      return {
        table_name: "record_activities",
        title: "Record Activity Logs",
        columns: [],
        lineDataUse: false,
      };
    }
  }, [menu]);

  return (
    <div className="admin-user-create-total-body">
      <div className="admin-user-create-title-box">
        <div className="admin-user-create-title">{table_info?.title ?? ""}</div>
      </div>
      <div className="admin-user-create-table-box">
        <ActivityLogTable
          rows={log_data}
          columns={createLoginActivityLogColumn()}
        />
      </div>
    </div>
  );
};

export default LogPage;

const query_fetch_data = async (menu) => {
  const result = { log_data: [] };

  try {
    const request_log_data = await userPermAxios.get(`/${menu}/get-logs-data`);

    const { success, data } = request_log_data;

    if (success) {
      result.log_data = data.map((c) => {
        if (menu === "login_log") {
          const { device_info } = c;
          return {
            ...c,
            device_info: device_info ? JSON.parse(device_info) : "",
          };
        }

        return c;
      });
    }
  } catch (err) {
  } finally {
    return result;
  }
};
