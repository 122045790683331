import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";

import CustomCheckBox from "@components/CustomCheckBox";
import "@styles/components.scss";

const CheckSingleSelectBox = ({
  title = "",
  width = 290,
  height = 300,
  checked,
  setChecked,
  selectList = [],
  add_fn = () => {},
}) => {
  const handleToggle = (value) => () => {
    const newChecked = [...checked];

    if (newChecked.indexOf(value) === -1) {
      setChecked([value]);
    } else {
      setChecked([]);
    }

    add_fn();
  };

  return (
    <div className="check-single-select-total-body">
      <div className="check-single-select-title-box">
        <div lassName="check-single-select-title">{title}</div>
      </div>
      <div className="check-single-select-list-box">
        <List sx={{ width, height, bgcolor: "background.paper" }}>
          {selectList.map((c) => {
            const { id, value, label } = c;

            return (
              <ListItem
                key={id}
                disablePadding
                className="check-single-select-list"
              >
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(value)}
                  dense
                  className="check-single-select-list-btn"
                >
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <CustomCheckBox
                      edge="start"
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <div
                    className="check-single-select-list-label"
                    // sx={{
                    //   fontSize: "14px",
                    // }}
                  >
                    {label}
                  </div>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </div>
    </div>
  );
};

export default CheckSingleSelectBox;
