import React, { useState } from "react";
import Button from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { userPermAxios } from "@utils/customAxios";
import useRefreshReactQuery from "@hooks/useRefreshReactQuery";

const EditorForm = ({
  targetComment,
  initial_value,
  type,
  comment_id,
  setViewMode,
  table_refresh_fn,
}) => {
  const { parent_table_name, table_name, id } = targetComment ?? {};
  const [value, setValue] = useState(initial_value ?? "");
  const [files, setFiles] = useState(null);
  const [refresh] = useRefreshReactQuery([
    "comment_data",
    parent_table_name,
    table_name,
    id,
  ]);

  const updateComment = async () => {
    const formData = new FormData();

    if (files) {
      for (const file in files) {
        if (file !== "length" && file !== "item") {
          const targetFile = files[file];

          formData.append(`files`, targetFile);
        }
      }
    }

    const bodyData = {
      text: value,
      commentableId: id,
      commentableType: table_name,
      commentId: comment_id ?? null,
    };

    formData.append("additionalData", JSON.stringify(bodyData));

    if (type === "create") {
      const requestCreateComment = await userPermAxios
        .post("/create-comments", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((err) => {
          return { success: false };
        });

      const { success } = requestCreateComment;

      if (success) {
        setValue("");
        setFiles(null);
        refresh();
        table_refresh_fn();
      }
    }

    if (type === "modified") {
      const requestCreateComment = await userPermAxios
        .post("/modified-comments", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((err) => {
          return { success: false };
        });

      const { success } = requestCreateComment;

      if (success) {
        setViewMode(true);
        setFiles(null);
        refresh();
        table_refresh_fn();
      }

      return success;
    }
  };

  return (
    <div className="comment-sheet-add-box">
      <div className="comment-sheet-markdown-box">
        <ReactQuill
          theme="snow"
          value={value}
          onChange={setValue}
          style={{ width: "100%" }}
        />
      </div>
      <div className="comment-sheet-control-box">
        <Button
          component="label"
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          startIcon={<FileUploadOutlinedIcon fontSize="small" />}
          sx={{ fontSize: 14, textTransform: "none" }}
        >
          Upload file {files && `(${files.length})`}
          <input
            type="file"
            multiple
            style={{
              clip: "rect(0 0 0 0)",
              clipPath: "inset(50%)",
              height: 1,
              overflow: "hidden",
              position: "absolute",
              bottom: 0,
              left: 0,
              whiteSpace: "nowrap",
              width: 1,
            }}
            onChange={(e) => {
              const fileCount = e.target.files.length;
              if (fileCount > 0) {
                setFiles(e.target.files);
              } else {
                setFiles(null);
              }
            }}
          />
        </Button>
        <Button
          // component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          sx={{ fontSize: 14, textTransform: "none" }}
          onClick={updateComment}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default EditorForm;
