import { userPermAxios } from "@/utils/customAxios";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import LogTable from "./LogTable";
import {
  createTableColumn,
  createTableLineColumn,
} from "@/utils/tableInitialData";
import { createRecordActivityLogColumn } from "./Column";

const RecordActivityLogSheet = ({ targetComment, tableHeight }) => {
  const { id, table_name } = targetComment;

  const tableColumn = useMemo(() => {
    if (table_name.includes("_lineitems")) {
      return createTableLineColumn(table_name.replaceAll("_lineitems", ""), []);
    } else {
      return createTableColumn(table_name, []);
    }
  }, [table_name]);

  const query_record_activity_log_data = useQuery(
    ["record_activity_log_data", id, table_name, tableColumn],
    async () => {
      return query_fetch_data(table_name, id, tableColumn);
    },
    {
      initialData: { log_data: [] },
    }
  );

  const { log_data } = query_record_activity_log_data.data;

  // const tableHeight = useMemo(() => {
  //   return height - 40 - 20 - 24 - 20 - 49 - 40;
  // }, [height]);

  return (
    <div className="attachment-sheet-total-body" style={{ height: "100%" }}>
      <LogTable
        rows={log_data}
        columns={createRecordActivityLogColumn()}
        tableHeight={tableHeight}
      />
    </div>
  );
};

export default RecordActivityLogSheet;

const query_fetch_data = async (tablename, rowId, tableColumn) => {
  const result = { log_data: [] };

  try {
    const request_log_data = await userPermAxios.get(
      `/${tablename}/${rowId}/get-record-activity-log`
    );

    const { success, data } = request_log_data;

    if (success) {
      result.log_data = data.map((c) => {
        const { field } = c;

        const targetColumn = tableColumn.find((c2) => {
          return c2.field === field;
        });

        return {
          ...c,
          field_name:
            field === "group_name"
              ? "Group Name"
              : targetColumn?.headerName ?? "",
        };
      });
    }
  } catch (err) {
  } finally {
    return result;
  }
};
