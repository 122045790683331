import React from "react";
import "@styles/components.scss";

const HorizontalDashLine = () => {
  return (
    <div className="horizontal-dash-border-line">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="556"
        height="2"
        viewBox="0 0 556 2"
        fill="none"
      >
        <path d="M0 1H556" stroke="#98A2B3" strokeDasharray="4 4" />
      </svg>
    </div>
  );
};

export default HorizontalDashLine;
