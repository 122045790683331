import React, { useEffect, useState, useMemo, useRef } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { QtyControlDataConsolidationModalStatus } from "@atoms/modalStatus";
import axios from "axios";
import { Modal, IconButton, Button } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { createRequestColumn } from "./Column";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SelectDataTable from "@components/SelectDataTable";
import MaterialAddModal from "@components/MaterialAddModal/MaterialAddModal";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";
import "./qtyControlDataConsolidationModal.scss";

const QtyControlDataConsolidationModal = () => {
  const { width, height } = useWindowDimensions();

  const [modalStatus, setModalStatus] = useRecoilState(
    QtyControlDataConsolidationModalStatus
  );

  const { open, target_id, inv_name } = modalStatus;

  const [dubleClick, setDubleClick] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);

  const query_material_inventory_list = useQuery(
    ["material_inventory_list", target_id, inv_name],
    () => {
      return query_fetch_data(target_id, inv_name);
    },
    {
      initialData: { material_list: [] },
    }
  );

  const { material_list } = query_material_inventory_list.data;

  const closeModal = () => {
    setModalStatus((prev) => ({
      ...prev,
      open: false,
      target_id: null,
      inv_name: null,
    }));
  };

  const button_disabled = useMemo(() => {
    if (!target_id || !inv_name || selectionModel.length === 0) {
      return true;
    }

    return false;
  }, [selectionModel, target_id, inv_name]);

  const modalHeight = height * 0.9;

  const createMaterialInventoryControlLine = async () => {
    try {
      const requestDataConsolidation = await userPermAxios.post(
        `/inventory_quantity_control/${target_id}/qty-control-consolidation-lineitem`,
        {
          lineData: selectionModel.map((c) => {
            const findLineData = material_list.find((c2) => c2.id === c);

            return findLineData;
          }),
        }
      );

      const { success } = requestDataConsolidation;

      if (success) {
        closeModal();
      }
    } catch (err) {
      create_alert_fn("warning", "Data Consolidation Error", () => {});
    } finally {
      setDubleClick(true);
    }
  };

  const openSubmitModal = () => {
    if (!dubleClick) return;
    setDubleClick(false);

    create_alert_fn(
      "info",
      "Are you sure you want to create a line item?",
      async () => {
        await createMaterialInventoryControlLine();
      }
    );
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="qty-control-data-consolidation-modal-total-body"
        style={{ width: width * 0.9, height: modalHeight }}
      >
        <div className="qty-control-data-consolidation-modal-header-box">
          <div className="qty-control-data-consolidation-modal-header-title">
            Data Consolidation
          </div>
          <IconButton
            onClick={closeModal}
            className="qty-control-data-consolidation-modal-close-btn"
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </div>

        <div
          className="qty-control-data-consolidation-modal-main-box"
          style={{ height: modalHeight - 70 - 76 - 32 }}
        >
          <SelectDataTable
            title="Material Inventory List"
            rows={material_list}
            columns={createRequestColumn()}
            selectModel={selectionModel}
            setSelectModel={setSelectionModel}
            table_height={modalHeight - 70 - 76 - 32 - 40}
            isRowSelectableField="material_use"
            searchUse={false}
          />
        </div>

        <div className="qty-control-data-consolidation-modal-btn-box">
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={closeModal}
            className="qty-control-data-consolidation-modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={openSubmitModal}
            className="qty-control-data-consolidation-modal-update-btn"
            disabled={button_disabled}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default QtyControlDataConsolidationModal;

const query_fetch_data = async (target_id, inv_name) => {
  const result = { material_list: [] };

  try {
    if (!inv_name) {
      return result;
    }

    const request_material_list = await userPermAxios.get(
      `/${target_id}/${inv_name}/get_material_inventory_list`
    );

    const { success, data } = request_material_list ?? {};

    if (success) {
      result.material_list = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};
