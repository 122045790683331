import React from "react";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";

const BlankAddCell = ({ right_icon }) => {
  return (
    <div className="table-blank-box">
      <div className="table-blank-border-box">
        <AddCircleOutlinedIcon fontSize="small" color="primary" />
        {right_icon}
      </div>
    </div>
  );
};

export default BlankAddCell;
