import React, { useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Tooltip from "@mui/material/Tooltip";
import { useRecoilValue } from "recoil";
import { UserSetting } from "@atoms/userInfo";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import ReCommentEidtorForm from "./ReCommentEidtorForm";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { userPermAxios } from "@utils/customAxios";
import FileDownloadBtn from "@components/FileDownloadBtn";

dayjs.extend(utc);
dayjs.extend(timezone);

const ReCommentCard = ({
  target_id,
  author_id,
  author_name,
  user_id,
  create_time,
  refresh_fn,
  value,
  parent_comment_id,
  table_name,
  row_id,
  comment_attachment,
  table_refresh_fn,
}) => {
  const userSetting = useRecoilValue(UserSetting);
  const [editorMode, setEdiorMode] = useState(false);

  const deleteComment = async () => {
    try {
      const reqeustDeleteComment = await userPermAxios.post(
        "/delete-comments",
        {
          commentId: target_id,
          commentableId: row_id,
          commentableType: table_name,
        }
      );

      const { success } = reqeustDeleteComment;

      if (success) {
        refresh_fn();
        table_refresh_fn();
      }
    } catch (err) {}
  };

  const today_diff = dayjs()
    .utcOffset(userSetting.timeZone, false)
    .diff(dayjs(create_time).utcOffset(userSetting.timeZone, false), "days");

  return (
    <div
      className={`re-comment-card-total-body ${editorMode && "editor-mode"}`}
    >
      {editorMode ? (
        <ReCommentEidtorForm
          target_id={target_id}
          initial_value={value}
          parent_comment_id={parent_comment_id}
          table_name={table_name}
          row_id={row_id}
          type="modified"
          setEditorMode={setEdiorMode}
          refresh_fn={refresh_fn}
          table_refresh_fn={table_refresh_fn}
        />
      ) : (
        <>
          <div className="re-comment-card-contents-box">
            <div className="re-comment-card-author-box">
              <div>{author_name}</div>
              <div>
                <Tooltip
                  title={dayjs(create_time)
                    .utcOffset(userSetting.timeZone, false)
                    .format(userSetting.timeFormat)}
                  placement="top"
                  sx={{
                    fontSize: 14,
                  }}
                >
                  <IconButton>
                    <AccessTimeOutlinedIcon fontSize="small" />
                    <div style={{ marginLeft: 5 }}>{today_diff}d</div>
                  </IconButton>
                </Tooltip>
                {author_id === user_id && (
                  <>
                    <Tooltip
                      title="Comment Modified"
                      placement="top"
                      sx={{
                        fontSize: 14,
                      }}
                    >
                      <IconButton onClick={() => setEdiorMode((prev) => !prev)}>
                        <CreateOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Comment Delete"
                      placement="top"
                      sx={{
                        fontSize: 14,
                      }}
                    >
                      <IconButton onClick={deleteComment}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
            <div
              className="re-comment-card-contents"
              dangerouslySetInnerHTML={{ __html: value }}
            />
          </div>
          <div className="comment-card-attach-list-box">
            {comment_attachment.map((c, i) => {
              return (
                <FileDownloadBtn
                  key={i}
                  url={c.file_url}
                  filename={c.filename}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ReCommentCard;
