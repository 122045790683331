import React from "react";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import useTableLazyLoading from "@hooks/useTableLazyLoading";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const InvenTable = ({ row = [], column = [], isLoading }) => {
  const apiRef = useGridApiRef();

  const [initialRows, rowCount, debouncedHandleFetchRows] = useTableLazyLoading(
    row,
    apiRef,
    column
  );

  return (
    <div className="material-inventroy-table-total-body">
      <DataGridPro
        rows={row}
        columns={column}
        initialState={{}}
        hideFooter
        apiRef={apiRef}
        loading={isLoading}
        rowHeight={40}
        columnHeaderHeight={40}
        sx={{
          ...materialTableStyle,
          ...TableScrollbarStyle,
        }}
        // rowCount={rowCount}
        // sortingMode="server"
        // filterMode="server"
        // rowsLoadingMode="server"
        // onFetchRows={debouncedHandleFetchRows}
        // onSortModelChange={(newSortModel) => {
        //   debouncedHandleFetchRows({
        //     sortModel: newSortModel,
        //   });
        // }}
        // onFilterModelChange={(newSortModel) => {
        //   debouncedHandleFetchRows({
        //     filterModel: newSortModel,
        //   });
        // }}
        disableRowSelectionOnClick
        disableColumnPinning
        disableColumnSelector
        disableDensitySelector
      />
    </div>
  );
};

export default InvenTable;
