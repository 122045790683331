import { useRef } from "react";
import QRCode from "qrcode";

const useQRCodeGenerator = (name) => {
  const canvasRef = useRef();

  const generateQRCode = async (value) => {
    try {
      // 데이터가 문자열인지 확인
      if (typeof value !== "string") {
        throw new Error("Invalid data: QR code value must be a string.");
      }

      // 특수 문자 인코딩 확인
      const validValue = encodeURIComponent(value);

      // QR 코드 생성
      await QRCode.toCanvas(canvasRef.current, validValue, {
        errorCorrectionLevel: "H",
      });
      const canvas = canvasRef.current;
      const imgData = canvas.toDataURL("image/png");

      // 이미지 다운로드
      const link = document.createElement("a");
      link.href = imgData;
      link.download = `${name}.png`;
      link.click();
    } catch (error) {
      console.error(error.message);
    }
  };

  return { canvasRef, generateQRCode };
};

export default useQRCodeGenerator;
