import React, { createContext, useContext, useEffect } from "react";

import axios from "axios";
import { setRecoil } from "recoil-nexus";
import { UserSetting } from "@atoms/userInfo";
import { history } from "@utils/history";

const { REACT_APP_API_URI } = process.env;

export const defaultAxios = axios.create({
  baseURL: REACT_APP_API_URI,
  headers: {
    "Content-Type": "application/json",
  },
});

export const userPermAxios = axios.create({
  baseURL: REACT_APP_API_URI,
  withCredentials: true,
});

userPermAxios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // alert("The token has expired. Please log in again.");

      setRecoil(UserSetting, null);

      // history.replace("/login");

      return new Promise(() => {});
    }

    return Promise.reject(error);
  }
);

// const NavigationContext = createContext();

// export const NavigationProvider = ({ children }) => {
//   const navigate = useNavigate();
//   return (
//     <NavigationContext.Provider value={navigate}>
//       {children}
//     </NavigationContext.Provider>
//   );
// };

// export const useNavigation = () => useContext(NavigationContext);

// const useAxiosInterceptors = () => {
//   const navigate = useNavigation();

//   useEffect(() => {
//     const responseInterceptor = userPermAxios.interceptors.response.use(
//       (response) => response.data,
//       (error) => {
//         if (error.response && error.response.status === 401) {
//           // alert("The token has expired. Please log in again.");
//           setRecoil(UserSetting, null);

//           console.log("Test");
//           navigate("/login");
//           // return new Promise(() => {}); // Return unresolved promise to stop further processing
//           return;
//         }
//         return Promise.reject(error);
//       }
//     );

//     return () => {
//       userPermAxios.interceptors.response.eject(responseInterceptor);
//     };
//   }, [navigate]);
// };

// export default useAxiosInterceptors;
