export const validateEmail = (value) => {
  const emailRegex = new RegExp(/\S+@\S+\.\S+/);

  if (!emailRegex.test(value)) {
    return "Please enter it in a valid email format.";
  }

  return null;
};

export const validatePassword = (value, id) => {
  if (value.length > 0 && value === id)
    //  return "패스워드가 사용자 계정과 동일합니다.";
    return "The password is the same as the user account.";
  if (hasRepeatedCharacters(value))
    // return "동일한 문자 또는 숫자가 3자 이상 사용되었습니다.";
    return "The same character or number is used three or more times.";
  if (hasSequentialCharacters(value))
    // return "주기성 문자나 키보드상의 연속된 배열이 3개 이상 사용되었습니다.";
    return "Sequential characters or keyboard patterns are used three or more times.";

  if (isOnlyNumericOrAlpha(value))
    // return "문자 또는 숫자만으로 구성된 패스워드는 허용되지 않습니다.";
    return "Passwords consisting only of letters or numbers are not allowed.";

  if (value.length > 0 && !isValidPassword(value)) {
    //return "패스워드는 다음의 문자 종류 중 2종류 이상을 조합하여 최소 10자리 이상 또는 3종류 이상을 조합하여 최소 8자리 이상의 길이로 작성하여야 합니다. * 영문 대문자(26개), 영문 소문자(26개), 숫자(10개), 특수문자(32개)";
    return "Passwords must be at least 10 characters long with a combination of at least two of the following types of characters, or at least 8 characters long with a combination of at least three types: * Uppercase English letters (26), lowercase English letters (26), numbers (10), special characters (32).";
  }

  return null; // 유효한 패스워드
};

const isValidPassword = (password) => {
  const upperCaseRegex = new RegExp(/[A-Z]/);
  const lowerCaseRegex = new RegExp(/[a-z]/);
  const numberRegex = new RegExp(/\d/);
  //eslint-disable-next-line
  const specialCharRegex = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/);

  const checks = [
    upperCaseRegex.test(password),
    lowerCaseRegex.test(password),
    numberRegex.test(password),
    specialCharRegex.test(password),
  ];

  const trueCounts = checks.filter(Boolean).length;

  if (trueCounts >= 3 && password.length >= 8) return true;
  if (trueCounts >= 2 && password.length >= 10) return true;

  return false;
};

const hasSequentialCharacters = (str) =>
  /(012|123|234|345|456|567|678|789|890|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|qwe|wer|ert|rty|tyu|yui|uio|iop|asd|sdf|dfg|fgh|ghj|hjk|jkl|zxc|xcv|cvb|vbn|bnm)/i.test(
    str
  );

const hasRepeatedCharacters = (str) => /(.)\1\1/.test(str);

const isOnlyNumericOrAlpha = (str) => /^[0-9]+$|^[a-zA-Z]+$/.test(str);
