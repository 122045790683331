import React from "react";
import dayjs from "dayjs";
import LabelSelect from "@components/LabelSelect";
import "./localeSetting.scss";

import { timeZoneList, timeFormatList, countries } from "@utils/userTimeZone";

const LocaleSetting = ({
  country,
  setCountry,
  language,
  setLanguage,
  timeZone,
  setTimeZone,
  timeFormat,
  setTimeFormat,
}) => {
  return (
    <div className="locale-setting-total-body">
      <div className="locale-setting-title">
        Language, date, and time format
      </div>

      <div className="locale-setting-select-box">
        <LabelSelect
          label="Country"
          value={country}
          setValue={setCountry}
          list={countries.map((c) => ({ name: c.label, value: c.label }))}
        />
        <LabelSelect
          label="Language"
          value={language}
          setValue={setLanguage}
          list={[
            { value: "en", name: "English" },
            { value: "th", name: "เกาหลี" },
          ]}
        />
        <LabelSelect
          label="Time Format"
          value={timeZone}
          setValue={setTimeZone}
          list={timeZoneList.map((c) => ({ name: c.name, value: c.name }))}
        />
        <LabelSelect
          label="Date Format"
          value={timeFormat}
          setValue={setTimeFormat}
          list={timeFormatList}
          helperText={
            !timeFormat && timeFormat === ""
              ? null
              : `ex) ${dayjs().format(timeFormat)}`
          }
        />
      </div>
    </div>
  );
};

export default LocaleSetting;
