import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import LoginPageBody from "@pages/login/LoginPageBody";
import LoginTitle from "@components/LoginTitle";
import LabelInput from "@components/LabelInput";
import PwInput from "@components/PwInput";
import LoginPageBottomBox from "@components/LoginPageBottomBox";
import { validateEmail, validatePassword } from "@utils/valueRegex";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";

import "./loginPage.scss";

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [pw, setPW] = useState("");
  const [dubleClick, setDubleClick] = useState(true);

  const emailCheck = useMemo(() => {
    if (email.length === 0) {
      return null;
    }

    return validateEmail(email);
  }, [email]);

  const pwCheck = useMemo(() => {
    if (pw.length === 0) {
      return null;
    }

    return validatePassword(pw);
  }, [pw]);

  const buttonDisabled = useMemo(() => {
    if (email.length > 0 && pw.length > 0 && !emailCheck && !pwCheck) {
      return false;
    }

    return true;
  }, [email, pw, emailCheck, pwCheck]);

  const requestSignUser = async () => {
    if (!dubleClick) return;
    setDubleClick(false);

    const result = {
      success: true,
      message: "",
      fn: () => {},
    };

    try {
      const requestSignUp = await userPermAxios.post("/login", {
        email: email,
        password: pw,
      });

      const { success, message } = requestSignUp;

      if (success) {
        result.success = success;
        result.message = message;
        result.fn = () => {
          navigate("/home");
        };
      }
    } catch (err) {
      result.success = false;

      if (err.response && err?.response?.data?.error) {
        result.message = err?.response?.data?.error;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(
        result.success ? "success" : "warning",
        result.message,
        result.fn
      );
    }
  };

  const onKeyDownEvent = (e) => {
    const keyCode = e.code;

    if (keyCode === "Enter") {
      requestSignUser();
    }
  };

  return (
    <LoginPageBody>
      <LoginTitle title="Sign In" sub_title="Sign in to your account." />

      <div className="login-page-contents-box">
        <div className="login-page-contents-top">
          <LabelInput
            label="Email"
            value={email}
            setValue={setEmail}
            placeholder="Email"
            error={emailCheck === null ? false : true}
            helperText={emailCheck}
          />
          <PwInput
            label="Password"
            value={pw}
            setValue={setPW}
            placeholder="Password"
            findPassUse={true}
            error={pwCheck === null ? false : true}
            helperText={pwCheck}
            onKeyDown={onKeyDownEvent}
          />
        </div>
        <LoginPageBottomBox
          button_disabled={buttonDisabled}
          button_label="Sign In"
          button_fn={requestSignUser}
          first_text="Not a Member yet?"
          second_text="Sign up"
          second_text_fn={() => {
            navigate("/sign-up");
          }}
        />
      </div>
    </LoginPageBody>
  );
};

export default LoginPage;
