import React from "react";
import "@styles/components.scss";

const LoginTitle = ({ title, sub_title }) => {
  return (
    <div className="login-page-title-box">
      {title && <div className="login-page-title">{title}</div>}
      {sub_title && (
        <div
          className="login-page-sub-title"
          dangerouslySetInnerHTML={{ __html: sub_title }}
        />
      )}
    </div>
  );
};

export default LoginTitle;
