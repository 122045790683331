import { useEffect, useState, useCallback, useMemo } from "react";
import { loadServerRows } from "@mui/x-data-grid-generator";
import { unstable_debounce as debounce } from "@mui/utils";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

const useTableLazyLoading = (rows = [], apiRef, custom_column) => {
  const [initialRows, setInitialRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const columnsWithComparator = custom_column.map((column) => {
    return {
      ...column,
      sortComparator:
        column?.sortComparator ||
        ((v1, v2) =>
          (v1 || "").toString().localeCompare((v2 || "").toString())),
      // filterable: column.filterable !== false,
      filterOperators: getGridStringOperators(),
    };
  });

  const fetchRow = useCallback(
    async (params) => {
      const { filterModel = { items: [] }, sortModel = [] } = params;

      const serverRows = await loadServerRows(
        rows,
        {
          filterModel,
          sortModel,
        },
        {
          minDelay: 300,
          maxDelay: 800,
          useCursorPagination: false,
        },
        columnsWithComparator
      );

      return {
        slice: serverRows.returnedRows.slice(
          params.firstRowToRender,
          params.lastRowToRender
        ),
        total: serverRows.returnedRows.length,
      };
    },
    [rows]
  );

  useEffect(() => {
    if (rows.length === 0) {
      return;
    }

    (async () => {
      const { slice, total } = await fetchRow({
        firstRowToRender: 0,
        lastRowToRender: 10,
        sortModel: [],
        filterModel: {
          items: [],
        },
      });

      setInitialRows(slice);
      setRowCount(total);
    })();
  }, [rows, fetchRow]);

  const handleFetchRows = useCallback(
    async (params) => {
      const { slice, total } = await fetchRow(params);

      if (apiRef.current) {
        apiRef.current.unstable_replaceRows(params.firstRowToRender, slice);
        setRowCount(total);
      }
    },
    [apiRef, fetchRow]
  );

  const debouncedHandleFetchRows = useMemo(
    () => debounce(handleFetchRows, 200),
    [handleFetchRows]
  );

  return [initialRows, rowCount, debouncedHandleFetchRows];
};

export default useTableLazyLoading;
