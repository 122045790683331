import { setRecoil, resetRecoil } from "recoil-nexus";
import { TargetComment } from "@/atoms/commnet";
import {
  PwSettingModalStatus,
  UserSettingModalStatus,
  DataConsolidationModalStatus,
  ReleaseDataConsolidationModalStatus,
  RequestDataConsolidationModalStatus,
  UserAddMenuModalStatus,
  MenuAddUserModalStatus,
  ProjectAddUserModalStatus,
  MaterialModalStatus,
} from "@/atoms/modalStatus";
import {
  UserInfo,
  UserSetting,
  UserAuthorization,
  UserTargetProject,
  UserGeneralMenu,
} from "@/atoms/userInfo";

const allResetStates = () => {
  const resetStates = [
    TargetComment,
    PwSettingModalStatus,
    UserSettingModalStatus,
    DataConsolidationModalStatus,
    ReleaseDataConsolidationModalStatus,
    RequestDataConsolidationModalStatus,
    UserAddMenuModalStatus,
    MenuAddUserModalStatus,
    ProjectAddUserModalStatus,
    MaterialModalStatus,
    UserInfo,
    UserSetting,
    UserAuthorization,
    UserTargetProject,
    UserGeneralMenu,
  ];

  resetStates.forEach((c) => resetRecoil(c));
};

export default allResetStates;
