import React, { useEffect, useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { userPermAxios } from "@utils/customAxios";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const LineItemTable = ({
  line_table_name,
  lineColumns,
  parent_id,
  parent_table_name,
  parent_api_ref,
  soketData,

  sales_id,
  edit_mode,
}) => {
  const lineApiRef = useGridApiRef();

  const query_lineitem_table = useQuery(
    ["line_table_data", line_table_name, parent_id],
    () => {
      return query_fetch_data(line_table_name, parent_id);
    },
    {
      initialData: { row: [] },
    }
  );

  const { row } = query_lineitem_table.data;

  useEffect(() => {
    if (soketData) {
      const { action, data, tablename } = soketData;

      const { parent_id: soket_id, addRow } = data ?? {};

      if (tablename === line_table_name && Number(soket_id) === parent_id) {
        if (action === "new-row") {
          lineApiRef.current.updateRows([
            {
              ...addRow,
            },
          ]);
        }

        if (action === "update-cell") {
          lineApiRef.current.updateRows([
            { id: data.id, [data.field]: data.value },
          ]);
        }

        if (action === "delete-row") {
          lineApiRef.current.updateRows([{ id: data.id, _action: "delete" }]);
        }

        if (action === "data-consolidation-cell") {
          lineApiRef.current.updateRows(data?.dataConsolidation ?? []);
        }
      }
    }
  }, [soketData, parent_id, lineApiRef]);

  const handleCellEditStart = (params, event) => {
    if (event.type === "keydown") {
      event.defaultMuiPrevented = true;
    }
  };

  const handleCellClick = useCallback(
    (params) => {
      const cellMode = lineApiRef.current.getCellMode(params.id, params.field);

      if (cellMode === "view" && params.colDef.editable) {
        lineApiRef.current.startCellEditMode({
          id: params.id,
          field: params.field,
        });
      }
    },
    [lineApiRef]
  );

  return (
    <div
      style={{ backgroundColor: "#F9FAFB", padding: "20px", maxHeight: 408 }}
    >
      <h3 style={{ marginTop: "0" }}>Line Item</h3>

      <DataGridPro
        rows={row ?? []}
        columns={lineColumns.map((c) => {
          const { field, editable: defaultEditable } = c;

          let editable = defaultEditable;

          if (defaultEditable) {
            if (field === "delete_cell") {
              editable = false;
            } else {
              editable = edit_mode;
            }
          }

          return {
            ...c,
            edit_mode,
            editable,
            parent_id,
            parent_table_name,
            parent_lead_value: row?.lead,
            parent_lead_id: sales_id,
          };
        })}
        initialState={{}}
        hideFooter
        columnHeaderHeight={40}
        disableRowSelectionOnClick
        // pinnedColumns={{ left: ["__check__", "col1"] }}
        // pinnedRows={{
        //   bottom: [{ index: Infinity, id: Infinity, col1: "+ lead Add" }],
        // }}
        apiRef={lineApiRef}
        rowHeight={40}
        sx={{
          ...materialTableStyle,
          ...TableScrollbarStyle,
        }}
        onCellEditStart={handleCellEditStart}
        onCellClick={handleCellClick}
        disableColumnPinning
        disableColumnSelector
        disableDensitySelector
      />
    </div>
  );
};

export default LineItemTable;

const query_fetch_data = async (table_name, record_id) => {
  const result = { row: [] };

  try {
    const requestRowData = await userPermAxios.get(
      `/${table_name}/detail/rows/${record_id}`
    );
    const { data } = requestRowData;

    result.row = data;
  } catch (err) {
  } finally {
    return result;
  }
};
