import { useState, useRef } from "react";

const useDragEvent = (initial_width) => {
  const [width, setWidth] = useState(initial_width); // 초기 너비 설정
  const dragStartX = useRef(null); // 드래그 시작 x 좌표 저장을 위한 ref

  const handleMouseDown = (event) => {
    event.preventDefault();
    dragStartX.current = event.clientX; // 드래그 시작 위치 설정
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (moveEvent) => {
    if (dragStartX.current !== null) {
      const currentX = moveEvent.clientX;
      const diffX = dragStartX.current - currentX; // 시작 X에서 현재 X를 빼서 차이 계산
      setWidth((prevWidth) => {
        const newWidth = Math.max(500, Math.min(1000, prevWidth + diffX)); // 너비 300px ~ 1000px 범위 제한
        return newWidth;
      });
      dragStartX.current = currentX; // 드래그 지속 갱신
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    dragStartX.current = null; // 드래그 종료 후 초기화
  };

  return [width, handleMouseDown];
};

export default useDragEvent;
