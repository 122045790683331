import React, { useState } from "react";
import {
  Grid,
  List,
  Card,
  CardHeader,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Divider,
} from "@mui/material";

const AssignProject = ({
  projectTotalSelectItems,
  setProjectTotalSelectItems,
  permProjectSelectItems,
  setPermProjectSelectItems,
}) => {
  const [checked, setChecked] = useState([]);

  const leftChecked = intersection(checked, projectTotalSelectItems);

  const readChecked = intersection(checked, permProjectSelectItems);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedFromReadToList = () => {
    setPermProjectSelectItems(permProjectSelectItems.concat(leftChecked));
    setProjectTotalSelectItems(not(projectTotalSelectItems, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedFromListToRead = () => {
    setProjectTotalSelectItems(projectTotalSelectItems.concat(readChecked));
    setPermProjectSelectItems(not(permProjectSelectItems, readChecked));
    setChecked(not(checked, readChecked));
  };

  const customList = (title, items, total) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 350,
          height: 538 - 58,
          bgcolor: "background.paper",
          overflow: "auto",
          boxSizing: "border-box",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value.id}-label`;

          return (
            <ListItemButton
              key={value.id}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.lead}`} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
    <div className="menu-add-select-list-total-body">
      <Grid item>
        {customList("Project List", projectTotalSelectItems, true)}
      </Grid>
      <div className="menu-add-select-list-right-total-body">
        <div className="menu-add-select-list-right-box">
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedFromReadToList}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedFromListToRead}
                disabled={readChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            {customList("Assign Project", permProjectSelectItems)}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default AssignProject;

function not(a, b) {
  return a.filter((value) => b.findIndex((c) => c.id === value.id) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.findIndex((c) => c.id === value.id) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}
