import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { JobNumberStatus } from "@atoms/modalStatus";
import { Modal, IconButton, Button } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import _ from "lodash";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";
import CheckSelectBox from "@/components/CheckSingleSelectBox";
import "./jobNumberSelectModal.scss";

const JobNumberSelectModal = () => {
  const [modalStatus, setModalStatus] = useRecoilState(JobNumberStatus);

  const { open, sales_id } = modalStatus;

  const [cateSelection, setCateSelection] = useState([]);
  const [scopeSelection, setScopeSelection] = useState([]);
  const [companySelection, setCompanySelection] = useState([]);
  const [dubleClick, setDubleClick] = useState(true);

  const query_job_number_list = useQuery(
    ["job_number_list", sales_id],
    () => {
      return query_fetch_data(sales_id);
    },
    {
      initialData: {
        prev_data: null,
        scope_list: [],
        company_list: [],
      },
      onSuccess: (data) => {
        const { prev_data, scope_list } = data;

        if (prev_data) {
          const { job_number_id, customer_id } = prev_data;

          if (job_number_id !== null) {
            const find_prev_select_value = scope_list.find(
              (c) => c.id === job_number_id
            );

            if (find_prev_select_value) {
              setCateSelection([find_prev_select_value.category]);
              setScopeSelection([find_prev_select_value.id]);
            }
          }

          if (customer_id !== null) {
            setCompanySelection([customer_id]);
          }
        }
      },
    }
  );

  const { scope_list, company_list } = query_job_number_list.data;

  const closeModal = () => {
    setModalStatus((prev) => ({
      ...prev,
      open: false,
      sales_id: null,
      query_key: null,
    }));
  };

  const select_category_list = useMemo(() => {
    const group_scope_list = _(scope_list)
      .groupBy("category")
      .map((objs, key) => {
        return {
          id: key,
          label: key,
          value: key,
        };
      })
      .value();

    return group_scope_list;
  }, [scope_list]);

  const select_scope_list = useMemo(() => {
    if (cateSelection.length === 0) {
      return [];
    }

    const cateValue = cateSelection?.[0] ?? "";

    return scope_list
      .filter((c) => c.category === cateValue)
      .map((c) => {
        const { id, scope_name, scope_code, scope_detail_name } = c;
        return {
          id,
          label: `${scope_name} - ${scope_detail_name} (${scope_code})`,
          value: id,
        };
      });
  }, [cateSelection, scope_list]);

  const select_company_list = useMemo(() => {
    return company_list.map((c) => {
      const { id, customer, customer_code } = c;
      return {
        id,
        label: `${customer} (${customer_code})`,
        value: id,
      };
    });
  }, [company_list]);

  const job_number_result = useMemo(() => {
    if ([scopeSelection, companySelection].every((c) => c.length > 0)) {
      const scopeValue = scopeSelection[0];
      const companyValue = companySelection[0];

      const scope_code = scope_list.find((c) => c.id === scopeValue);
      const comapny_code = company_list.find((c) => c.id === companyValue);

      return `${scope_code.scope_code}${comapny_code.customer_code}-******`;
    }

    return;
  }, [scope_list, company_list, scopeSelection, companySelection]);

  const button_disabled = useMemo(() => {
    return [scopeSelection, companySelection].every((c) => c.length > 0);
  }, [scopeSelection, companySelection]);

  const onSubmit = async () => {
    if (!dubleClick) return;
    setDubleClick(false);

    const result = {
      status: "",
      message: "",
      fn: () => {},
    };

    try {
      const request_add_sales_job_number = await userPermAxios.post(
        `${sales_id}/add_sales_job_number`,
        {
          jobNumberId: scopeSelection?.[0],
          customerId: companySelection?.[0],
        }
      );

      const { success, message } = request_add_sales_job_number;

      if (success) {
        result.status = "success";
        result.message = message;
        result.fn = () => {
          closeModal();
        };
      }
    } catch (err) {
      result.status = "warning";
      if (err.response && err?.response?.data?.message) {
        result.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(result.status, result.message, result.fn);
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="job-number-select-modal-total-body"
        style={{ width: "auto" }}
      >
        <div className="job-number-select-modal-header-box">
          <div className="job-number-select-modal-header-title">Job Number</div>
          <IconButton
            onClick={closeModal}
            className="job-number-select-modal-close-btn"
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </div>

        <div className="job-number-select-modal-main-box">
          <div className="job-number-select-box">
            <CheckSelectBox
              title="Category"
              checked={cateSelection}
              setChecked={setCateSelection}
              selectList={select_category_list}
              add_fn={() => {
                setScopeSelection([]);
              }}
            />
            <CheckSelectBox
              title="Scope"
              checked={scopeSelection}
              setChecked={setScopeSelection}
              selectList={select_scope_list}
            />
            <CheckSelectBox
              title="Customer"
              checked={companySelection}
              setChecked={setCompanySelection}
              selectList={select_company_list}
            />
          </div>
          <div className="job-number-select-result-box">
            <div className="job-number-select-result">Results:</div>
            <div className="job-number-select-result-code">
              {job_number_result}
            </div>
          </div>
        </div>

        <div className="job-number-select-modal-btn-box">
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={closeModal}
            className="job-number-select-modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            className="job-number-select-modal-update-btn"
            disabled={!button_disabled}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default JobNumberSelectModal;

const query_fetch_data = async (sales_id) => {
  let result = { prev_data: null, scope_list: [], company_list: [] };

  try {
    const request_job_number_data = await userPermAxios.get(
      `${sales_id}/get_job_number_data`
    );

    const { success, data } = request_job_number_data;

    if (success) {
      result.prev_data = data?.prev_data;
      result.scope_list = data?.scope_list ?? [];
      result.company_list = data?.company_list ?? [];
    }
  } catch (err) {
  } finally {
    return result;
  }
};
