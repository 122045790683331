import React from "react";

const PermissuonBtnIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7564 8.15945C16.7564 8.11645 16.7215 8.08158 16.6785 8.08158H3.32799C3.28499 8.08158 3.25012 8.11645 3.25012 8.15945V18.1721C3.25012 18.2151 3.28499 18.2499 3.328 18.2499H10.7657C9.88137 16.4094 9.35633 14.0476 9.74216 11.1522C9.81467 10.6081 10.1922 10.167 10.6913 10.0042C11.2894 9.80902 12.3062 9.48716 13.3154 9.21361C14.3024 8.94605 15.3507 8.70483 15.9775 8.70483C16.6044 8.70483 17.6527 8.94605 18.6397 9.21361C19.6489 9.48716 20.6657 9.80902 21.2638 10.0042C21.7629 10.167 22.1404 10.6081 22.2129 11.1522C22.7553 15.2225 21.4976 18.2383 19.9722 20.2339C19.2123 21.2279 18.3865 21.9687 17.6839 22.4642C17.3328 22.7117 17.0074 22.9016 16.7311 23.0317C16.4759 23.1519 16.2027 23.2502 15.9775 23.2502C15.7523 23.2502 15.4792 23.1519 15.2239 23.0317C14.9476 22.9016 14.6223 22.7117 14.2712 22.4642C13.5686 21.9687 12.7428 21.2279 11.9829 20.2339C11.8859 20.107 11.79 19.976 11.6956 19.8409H3.328C2.40634 19.8409 1.65918 19.0937 1.65918 18.1721V8.15945C1.65918 7.23781 2.40633 6.49068 3.32799 6.49068H4.99681V2.41877C4.99681 1.49713 5.74396 0.75 6.66562 0.75H13.3409C14.2625 0.75 15.0097 1.49713 15.0097 2.41877V4.08754C15.0097 4.48235 14.7355 4.81312 14.3671 4.89978C14.3055 4.91426 14.2413 4.92193 14.1753 4.92193C14.1093 4.92193 14.0451 4.91426 13.9835 4.89978C13.6151 4.81312 13.3409 4.48235 13.3409 4.08754V3.25316C13.3409 2.79234 12.9673 2.41877 12.5065 2.41877L7.50003 2.41877C7.0392 2.41877 6.66562 2.79234 6.66562 3.25316V6.49068H16.6785C17.6002 6.49068 18.3473 7.23781 18.3473 8.15945V9.13532C17.7935 8.99035 17.2354 8.86011 16.7564 8.78171V8.15945ZM17.4655 14.7378C17.4655 13.9163 16.7995 13.2502 15.9779 13.2502C15.1563 13.2502 14.4902 13.9163 14.4902 14.7378C14.4902 15.3855 14.9042 15.9366 15.482 16.1408L15.1002 18.1147C15.041 18.4207 15.2754 18.7048 15.5871 18.7048H16.3687C16.6803 18.7048 16.9147 18.4207 16.8555 18.1147L16.4737 16.1408C17.0515 15.9366 17.4655 15.3855 17.4655 14.7378Z"
        fill="#344054"
      />
    </svg>
  );
};

export default PermissuonBtnIcon;
