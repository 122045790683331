import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { ContactPointStatus } from "@atoms/modalStatus";
import { Modal, IconButton, Button } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import ContactPointTable from "./ContactPointTable";
import AddModal from "./AddModal";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";
import { createContactPointColumn } from "./Columns";

import "./contactPointModal.scss";

const ContactPointModal = () => {
  const [modalStatus, setModalStatus] = useRecoilState(ContactPointStatus);

  const {
    open,
    table_name,
    sales_id,
    customer_id,
    admin_user = false,
    user_perm = "read",
    query_key,
  } = modalStatus;

  const [addModalStatus, setAddModalStatus] = useState({
    open: false,
    contact_name: "",
    phone: "",
    email: "",
    job_name: "",
  });

  const [parent_refresh] = useRefreshReactQuery(query_key);

  const [refresh] = useRefreshReactQuery([
    "contact_point_list",
    sales_id,
    customer_id,
  ]);

  const query_contact_point_list = useQuery(
    ["contact_point_list", sales_id, customer_id],
    () => {
      return query_fetch_data(sales_id, customer_id);
    },
    {
      initialData: { list: [] },
    }
  );

  const { list } = query_contact_point_list.data;

  const modify_perm = useMemo(() => {
    if (admin_user) {
      return true;
    }

    if (user_perm === "edit") {
      return true;
    }

    return false;
  }, [admin_user, user_perm]);

  const closeModal = () => {
    setModalStatus((prev) => ({
      ...prev,
      open: false,
      table_name: null,
      sales_id: null,
      customer_id: null,
    }));

    parent_refresh();
  };

  const openAddModal = () => {
    setAddModalStatus((prev) => {
      return {
        ...prev,
        open: true,
      };
    });
  };

  const closeAddModal = () => {
    setAddModalStatus((prev) => {
      return {
        ...prev,
        open: false,
        contact_name: "",
        phone: "",
        email: "",
        job_name: "",
      };
    });
  };

  const handleAddModalData = (value, field) => {
    setAddModalStatus((prev) => {
      return {
        ...prev,
        [field]: value,
      };
    });
  };

  const addContractPoint = async () => {
    let result = {
      status: "success",
      message: "",
      fn: () => {},
    };
    try {
      const request_add_contact_point = await userPermAxios.post(
        `${customer_id}/${sales_id}/create_contact_point`,
        {
          contact_name: addModalStatus.contact_name,
          phone: addModalStatus.phone,
          email: addModalStatus.email,
          job_name: addModalStatus.job_name,
        }
      );

      const { success, message } = request_add_contact_point;

      if (success) {
        result.status = "success";
        result.message = message;
        result.fn = () => {
          refresh();
          closeAddModal();
        };
      }
    } catch (err) {
      result.status = "warning";
      if (err.response && err?.response?.data?.message) {
        result.message = err?.response?.data?.message;
      }
    } finally {
      create_alert_fn(result.status, result.message, result.fn);
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="contact-point-modal-total-body" style={{ width: 720 }}>
        <div className="contact-point-modal-header-box">
          <div className="contact-point-modal-header-title">Contact Points</div>
          <IconButton
            onClick={closeModal}
            className="contact-point-modal-close-btn"
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </div>

        <div className="contact-point-modal-main-box">
          <ContactPointTable
            title={"Contact Points List"}
            rows={list}
            columns={createContactPointColumn(
              customer_id,
              sales_id,
              modify_perm
            )}
          />
        </div>

        <div className="contact-point-modal-btn-box">
          <Button
            onClick={openAddModal}
            className="contact-point-modal-update-btn"
            disabled={!modify_perm}
          >
            Add
          </Button>
        </div>
        {addModalStatus.open && (
          <AddModal
            addModalStatus={addModalStatus}
            onClose={closeAddModal}
            onChange={handleAddModalData}
            onSubmit={addContractPoint}
          />
        )}
      </div>
    </Modal>
  );
};

export default ContactPointModal;

const query_fetch_data = async (sales_id, customer_id) => {
  const result = { list: [] };

  try {
    if (!sales_id) {
      return result;
    }

    const request_contact_point_list = await userPermAxios.get(
      `/${customer_id}/${sales_id}/get_contact_points_list`
    );

    const { success, data } = request_contact_point_list;

    if (success) {
      result.list = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};
