import React, { useMemo } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useRecoilValue } from "recoil";
import { UserSetting } from "@atoms/userInfo";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { ThemeProvider } from "@emotion/react";
import { datePickerTheme } from "@styles/muiThemes";

import "@styles/components.scss";

dayjs.extend(utc);
dayjs.extend(timezone);

const DefaultDateRange = ({ dateRange, setDateRange }) => {
  const { timeZone, timeFormat } = useRecoilValue(UserSetting);

  const dateRangeValue = useMemo(() => {
    const { start, end } = dateRange;

    return [start, end].map((c) => dayjs(c).utcOffset(timeZone, false));
  }, [dateRange]);

  const handleDateRange = (e) => {
    const [startValue, endValue] = e;

    if (startValue && endValue) {
      setDateRange((prev) => {
        return {
          ...prev,
          start: startValue.utc().toDate(),
          end: endValue.utc().toDate(),
        };
      });
    }
  };

  return (
    <div className="default-date-range-picker-body">
      <ThemeProvider theme={datePickerTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            value={dateRangeValue}
            onChange={handleDateRange}
            format="YYYY. MM. DD"
            slotProps={{ textField: { label: "" } }}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
};

export default DefaultDateRange;
