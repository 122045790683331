import { createTheme } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { styled } from "@mui/system";

export const labelInputTheme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: 600,
          fontStyle: "normal",
          lineHeight: "20px",
          fontFamily: "Inter",
          color: "var(--Gray-700, #344054)",
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: "100%",
          borderRadius: "8px",
          border: "1px solid var(--Gray-300, #D0D5DD)",
          background: "var(--White, #FFF)",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

          "&:hover": {
            borderColor: "var(--Gray-300, #D0D5DD)",
          },

          "&.Mui-focused": {
            border: "1px solid var(--Brand-400, #3FC8FF)",
            background: "var(--White, #FFF)",
            boxShadow:
              "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(204, 250, 255, 0.50)",
          },
        },
        input: {
          padding: "10px",

          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          color: "var(--Gray-600, #6b7280)",
          margin: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: { root: {} },
    },
  },
});

export const labelInputTheme2 = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: 600,
          fontStyle: "normal",
          lineHeight: "20px",
          fontFamily: "Inter",
          color: "var(--Gray-700, #344054)",
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: "100%",
          borderRadius: "8px",
          // border: "1px solid var(--Gray-300, #D0D5DD)",
          background: "var(--Netural-White, #FFF)",

          "&:hover": {
            borderColor: "var(--Gray-300, #D0D5DD)",
          },

          "&.Mui-focused": {
            border: "1px solid var(--Brand-400, #3FC8FF)",
            background: "var(--White, #FFF)",
            // boxShadow:
            //   "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(204, 250, 255, 0.50)",
          },
        },
        input: {
          padding: "8px",
          height: "20px",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          color: "var(--Gray-600, #6b7280)",
          margin: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: { root: {} },
    },
  },
});

export const defaultButtonTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          width: "100%",
          border: "1px solid var(--Gray-300, #D0D5DD)",
          borderRadius: "8px",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

          color: "var(--Gray-700, #344054)",

          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px",

          "&:hover": {
            border: "1px solid var(--Gray-300, #D0D5DD)",
            background: "var(--Brand-50, #F4FEFF)",
          },
        },
      },
    },
  },
});

export const labelSelectTheme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: 600,
          fontStyle: "normal",
          lineHeight: "20px",
          fontFamily: "Inter",
          color: "var(--Gray-700, #344054)",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "8px 32px 8px 8px",
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          width: "100%",
          borderRadius: "8px",
          border: "1px solid var(--Gray-300, #D0D5DD)",
          background: "var(--White, #FFF)",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px",

          "&:hover": {
            borderColor: "var(--Gray-300, #D0D5DD)",
          },

          "&.Mui-focused": {
            border: "1px solid var(--Brand-400, #3FC8FF)",
            background: "var(--White, #FFF)",
            boxShadow:
              "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(204, 250, 255, 0.50)",
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px",
        },
      },
    },
  },
});

export const datePickerTheme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: "100%",
          borderRadius: "8px",
          border: "1px solid var(--Gray-300, #D0D5DD)",
          background: "var(--White, #FFF)",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

          "&:hover": {
            borderColor: "var(--Gray-300, #D0D5DD)",
          },

          "&.Mui-focused": {
            border: "1px solid var(--Brand-400, #3FC8FF)",
            background: "var(--White, #FFF)",
            boxShadow:
              "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(204, 250, 255, 0.50)",
          },
        },
        input: {
          padding: "10px",

          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px",
        },
      },
    },
  },
});

export const adminTableTheme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {},
    },
  },
});

export const materialTableStyle = {
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    display: "flex",
    justifyContent: "center",
  },

  "& .MuiDataGrid-columnHeader": {
    background: "#F9FAFB",
  },

  "& .MuiDataGrid-columnHeaderTitle": {
    color: "var(--Gray-700, #344054)",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  "& .MuiDataGrid-cell": {
    borderColor: "#D0D5DD", // Cell border color
  },
  "& .MuiDataGrid-row": {
    backgroundColor: "#fff", // Row background color
    "&:hover": {
      backgroundColor: "#F4FEFF", // Hover effect
    },
  },
  "& .MuiDataGrid-footerContainer": {
    backgroundColor: "#fff", // 페이지네이션 배경색
  },
  "& .MuiTablePagination-root": {
    backgroundColor: "#fff", // 페이지네이션 루트 배경색
  },
  "& .MuiTablePagination-toolbar": {
    backgroundColor: "#fff", // 페이지네이션 툴바 배경색
  },
};

export const TableScrollTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        *::-webkit-scrollbar {
          width: 0.4em;
          height: 0.6em;
        }
        *::-webkit-scrollbar-track {
          background: #FAFAFA !important;
          borde-radius: 8px;
        }
        *::-webkit-scrollbar-thumb {
          background-color: #C8C8C8;
          border-radius: 8px;
        }
        *::-webkit-scrollbar-thumb:hover {
          background: '#aaa',
        }
      `,
    },
  },
});

export const TableScrollbarStyle = {
  "& .MuiDataGrid-scrollbarContent": {
    width: "6px",
    borderRadius: "3px",
    right: "5px",
  },
  "& .MuiDataGrid-scrollbar::-webkit-scrollbar": {
    width: "6px",

    borderRadius: "3px",
  },
  "& .MuiDataGrid-scrollbar::-webkit-scrollbar-track": {
    background: "none",
    borderRadius: "3px",
  },
  "& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb": {
    background: "rgba(0, 0, 0, 0.45)",
    borderRadius: "3px",
  },
  "& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb:hover": {
    background: "rgba(0, 0, 0, 0.45)",
    borderRadius: "3px",
  },

  "& .MuiDataGrid-scrollbar--horizontal": {
    height: "6px",
    bottom: "5px",
  },

  "& .MuiDataGrid-scrollbar--vertical": {
    right: "5px",
  },
};
