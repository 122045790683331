import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useQuery } from "react-query";
import {
  GridLogicOperator,
  DataGridPremium,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";
import { userPermAxios } from "@utils/customAxios";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const GroupLineItemTable = ({
  lineColumns,
  parent_id,
  line_table_name,
  row_grouping_model,
  column_visibility_model,
}) => {
  const [filterModel, setFilterModel] = useState({
    items: [],
    logicOperator: GridLogicOperator.Or,
  });

  // const apiRef = useGridApiRef();

  const query_lineitem_table = useQuery(
    ["line_table_data", line_table_name, parent_id],
    () => {
      return query_fetch_data(line_table_name, parent_id);
    },
    {
      initialData: { row: [] },
    }
  );

  const { row } = query_lineitem_table.data;

  return (
    <div
      style={{ backgroundColor: "#F9FAFB", padding: "20px", maxHeight: 408 }}
    >
      <h3 style={{ marginTop: "0" }}>Line Item</h3>
      <DataGridPremium
        rows={row ?? []}
        columns={lineColumns}
        hideFooter
        columnHeaderHeight={40}
        disableRowSelectionOnClick
        rowHeight={40}
        sx={{
          ...materialTableStyle,
          ...TableScrollbarStyle,
          ...{ ".table-text-input-box": { width: "auto" } },
        }}
        disableColumnPinning
        disableColumnSelector
        disableDensitySelector
        defaultGroupingExpansionDepth={row_grouping_model.length}
        initialState={{
          columns: {
            columnVisibilityModel: column_visibility_model,
          },
          rowGrouping: {
            // model: ["sales_lead"],
            model: row_grouping_model,
          },
        }}
        filterModel={filterModel}
        onFilterModelChange={(e) => setFilterModel(e)}
      />
    </div>
  );
};

export default GroupLineItemTable;

const query_fetch_data = async (table_name, record_id) => {
  const result = { row: [] };

  try {
    const requestRowData = await userPermAxios.get(
      `/${table_name}/detail/rows/${record_id}`
    );
    const { data } = requestRowData;

    result.row = data;
  } catch (err) {
  } finally {
    return result;
  }
};
