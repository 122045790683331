import React from "react";
import {
  CustomNormalTextTypeCell,
  CustomDateTypeCell,
} from "@components/Cells";

export const createRecordActivityLogColumn = () => {
  return [
    {
      field: "event_name",
      headerName: "Action",
      width: 150,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "field_name",
      headerName: "Field",
      width: 150,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "prev_value",
      headerName: "Prev Value",
      width: 200,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "new_value",
      headerName: "New Value",
      width: 200,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "username",
      headerName: "User Name",
      width: 150,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "occurred_at",
      headerName: "Action Time",
      width: 200,
      data_type: "date",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
    },
  ];
};
