import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Select, MenuItem } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import DefaultDateRange from "@components/DefaultDateRange";
import { materialCateList } from "@utils/colorList";
import { labelSelectTheme } from "@styles/muiThemes";

dayjs.extend(utc);
dayjs.extend(timezone);

const FilterCard = ({ dateRange, setDateRange, category, setCategory }) => {
  const handleChange = (event) => {
    setCategory(event.target.value);
  };
  return (
    <div className="material-inventroy-filter-body">
      <DefaultDateRange dateRange={dateRange} setDateRange={setDateRange} />

      <ThemeProvider theme={labelSelectTheme}>
        <Select
          value={category}
          onChange={handleChange}
          // renderValue={(selected) => selected.name}
          sx={{
            width: "100%",
          }}
        >
          {[{ name: "Overall", value: "overall" }, ...materialCateList].map(
            (option, i) => (
              <MenuItem key={i} value={option.value}>
                {option.name}
              </MenuItem>
            )
          )}
        </Select>
      </ThemeProvider>
    </div>
  );
};

export default FilterCard;
