import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";

import { CustomNormalTextTypeCell, CustomNumberTypeCell } from "../Cells";

export const createRequestColumn = () => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "inv_name",
      headerName: "Inventory Name",
      width: 200,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_code",
      headerName: "Material Code",
      width: 200,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_description",
      headerName: "Description",
      flex: 1,
      minWidth: 350,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_uom",
      headerName: "Unit",
      data_type: "string",
      width: 100,
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "stock_qnty",
      headerName: "Stock Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
  ];
};

const CustomDetailCheckRow = ({ params }) => {
  const { id, api } = params;

  if (id === Infinity) {
    return null;
  }

  return (
    <input
      type="checkbox"
      className="custom-check-box"
      checked={params.api.isRowSelected(params.id)}
      onChange={(event) => {
        if (event.target.checked) {
          api.selectRow(params.id, true);
        } else {
          api.selectRow(params.id, false);
        }
      }}
    />
  );
};
