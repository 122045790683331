import { useState, useLayoutEffect, useRef } from "react";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { IconButton } from "@mui/material";
import {
  CustomNormalTextTypeCell,
  // CustomEditNormalTextTypeCell,
  // LineDeleteBtnCell,
} from "@components/Cells";
import DeleteIcon from "@components/Icons/DeleteIcon";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";

export const createContactPointColumn = (
  customer_id,
  sales_id,
  modify_perm
) => {
  return [
    {
      field: "delete_cell",
      headerName: "",
      width: 40,
      editable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <LineDeleteBtnCell
            params={params}
            table_name={"customer_contact_point"}
            customer_id={customer_id}
            sales_id={sales_id}
            modify_perm={modify_perm}
          />
        );
      },
    },
    {
      field: "contact_name",
      headerName: "Name",
      flex: 1,
      data_type: "",
      editable: modify_perm,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={"customer_contact_point"}
            customer_id={customer_id}
            sales_id={sales_id}
          />
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
      data_type: "",
      editable: modify_perm,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={"customer_contact_point"}
            customer_id={customer_id}
            sales_id={sales_id}
          />
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      data_type: "",
      editable: modify_perm,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={"customer_contact_point"}
            customer_id={customer_id}
            sales_id={sales_id}
          />
        );
      },
    },
    {
      field: "job_name",
      headerName: "Job",
      flex: 1,
      data_type: "",
      editable: modify_perm,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={"customer_contact_point"}
            customer_id={customer_id}
            sales_id={sales_id}
          />
        );
      },
    },
  ];
};

const CustomEditNormalTextTypeCell = ({
  params,
  table_name,
  customer_id,
  sales_id,
}) => {
  const { id, value, field, hasFocus, colDef = {} } = params;

  const [inputValue, setInputValue] = useState(value ?? "");

  const apiRef = useGridApiContext();

  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus) {
      ref?.current?.focus();
    } else {
      if (id !== Infinity) {
        updateRows();
      }
    }
    // eslint-disable-next-line
  }, [hasFocus, id]);

  const updateRows = async () => {
    if (!inputValue || inputValue.replaceAll(" ", "").length === 0) {
      create_alert_fn("warning", "This is a required value.");
      setInputValue(value);
    } else {
      if (inputValue === value) {
        return;
      }

      const requestUpdate = await updateCellData(
        id,
        field,
        inputValue,
        customer_id,
        sales_id
      );

      const { success } = requestUpdate;

      if (success) {
        apiRef.current.updateRows([
          {
            id,
            [field]: inputValue,
          },
        ]);
      }
    }
  };

  const handleKeyPress = (e) => {
    const eKey = e.key;

    if (eKey === "Enter" || eKey === "Tab") {
      updateRows();
    }
  };

  if (id === Infinity) {
    return null;
  }

  return (
    <div className="table-text-input-box">
      <input
        ref={ref}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => handleKeyPress(e)}
      />
    </div>
  );
};

const LineDeleteBtnCell = ({
  params,
  table_name,
  customer_id,
  sales_id,
  modify_perm,
}) => {
  const { id, colDef = {} } = params;

  // const { edit_mode } = colDef;

  const apiRef = useGridApiContext();

  if (id === Infinity) {
    return null;
  }

  const handleDeleteBtn = async () => {
    const requestDeleteRow = await deleteRowData(id, customer_id, sales_id);

    const { success, message } = requestDeleteRow;

    if (success) {
      apiRef.current.updateRows([{ id, _action: "delete" }]);
    } else {
      return create_alert_fn("warning", message, () => {});
    }
  };

  return (
    <IconButton
      className="line-delete-cell-btn"
      disabled={!modify_perm}
      onClick={handleDeleteBtn}
    >
      <DeleteIcon />
    </IconButton>
  );
};

export const updateCellData = async (
  id,
  field,
  inputValue,
  customer_id,
  sales_id
) => {
  try {
    const requestUpdateCellData = await userPermAxios.post(
      `/${customer_id}/${sales_id}/update_contact_point`,
      {
        id,
        field,
        inputValue,
      }
    );

    return requestUpdateCellData;
  } catch (err) {
    let message = "";

    if (err.response && err?.response?.data?.message) {
      message = err?.response?.data?.message;
    }

    return { success: false, message };
  }
};

export const deleteRowData = async (id, customer_id, sales_id) => {
  try {
    const requestDeleteRow = await userPermAxios.delete(
      `${customer_id}/${sales_id}/delete_contact_point/${id}`
    );

    return requestDeleteRow;
  } catch (err) {
    let message = "";

    if (err.response && err?.response?.data?.message) {
      message = err?.response?.data?.message;
    }

    return { success: false, message };
  }
};
