import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import LoginPageBody from "@pages/login/LoginPageBody";
import LoginTitle from "@components/LoginTitle";
import LabelInput from "@components/LabelInput";
import DefaultButton from "@components/DefaultButton";

import { defaultAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";
import { validateEmail } from "@utils/valueRegex";

import "./loginPage.scss";

const ForgetPWPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [dubleClick, setDubleClick] = useState(true);

  const sendResetMail = async () => {
    if (!dubleClick) return;

    setDubleClick(false);

    const result = {
      success: true,
      message: "",
      fn: () => {
        setDubleClick(true);
        return null;
      },
    };

    try {
      const requestSendMail = await defaultAxios
        .post("/resetpw", {
          email,
        })
        .then((data) => {
          return { ...data.data, status: data.status };
        })
        .catch((err) => {
          const { response } = err;

          const { status, data } = response;

          return {
            ...data,
            status,
          };
        });

      const { success, message } = requestSendMail;

      if (success) {
        result.fn = () => {
          setDubleClick(true);
          return;
        };
      }

      result.success = success;
      result.message = message;
    } catch (err) {
      result.success = false;
      result.message = "error";
    } finally {
      return create_alert_fn(
        result.success ? "success" : "warning",
        result.message,
        result.fn
      );
    }
  };

  const emailCheck = useMemo(() => {
    if (email.length === 0) {
      return null;
    }

    return validateEmail(email);
  }, [email]);

  const buttonDisabled = useMemo(() => {
    if (email.length > 0 && !emailCheck) {
      return false;
    }

    return true;
  }, [email, emailCheck]);

  return (
    <LoginPageBody>
      <LoginTitle
        title="Forgot Password?"
        sub_title="Enter your email to rest your password."
      />

      <div className="login-page-contents-box">
        <div className="login-page-contents-top">
          <LabelInput
            label="Email"
            value={email}
            setValue={setEmail}
            placeholder="Email"
            error={emailCheck === null ? false : true}
            helperText={emailCheck}
          />
        </div>
        <div className="forget-password-button-total-box">
          <div className="forget-password-button-box">
            <DefaultButton
              label="Submit"
              disabled={buttonDisabled}
              onClick={sendResetMail}
              width={120}
            />
            <DefaultButton
              label="Cancel"
              disabled={false}
              onClick={() => {
                navigate("/login");
              }}
              width={120}
            />
          </div>
          {/* <div className="includes-link-text-box">
            <div className="normal-text">
              Forgot or lost your email address?
            </div>
            <div className="under-line-text">Click here</div>
          </div> */}
        </div>
      </div>
    </LoginPageBody>
  );
};

export default ForgetPWPage;
