import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { MenuAddUserModalStatus } from "@/atoms/modalStatus";
import { Modal, IconButton, Button } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";
import SelectDataList from "./SelectDataList";
import "./menuAdduserModal.scss";

const MenuAdduserModal = () => {
  const [modalStatus, setModalStatus] = useRecoilState(MenuAddUserModalStatus);
  const { open, target_id, menu_url, menu_name } = modalStatus;

  const [dubleClick, setDubleClick] = useState(true);
  const [readSelectItems, setReadSelectItems] = useState([]);
  const [editSelectItems, setEditSelectItems] = useState([]);

  const closeModal = () => {
    setModalStatus((prev) => ({
      ...prev,
      open: false,
      target_id: null,
      menu_url: null,
      menu_name: null,
    }));
  };

  const query_project_perm_add_user_data = useQuery(
    ["project_perm_menu_add_user_data", menu_url, menu_name],
    () => {
      return query_fetch_data(menu_url, menu_name);
    },
    {
      initialData: { table_data: [] },
    }
  );

  const { table_data } = query_project_perm_add_user_data.data;

  const button_disabled = useMemo(() => {
    if (readSelectItems.length > 0 || editSelectItems.length > 0) {
      return false;
    }

    return true;
  }, []);

  const addUsertoMenu = async () => {
    try {
      const requestAddUser = await userPermAxios.post(
        `/${target_id}/${menu_url}/${menu_name}/add-user-to-menu`,
        {
          readData: readSelectItems.map((c) => {
            return { ...c, permission_level: "read" };
          }),
          editData: editSelectItems.map((c) => {
            return { ...c, permission_level: "edit" };
          }),
        }
      );

      const { success } = requestAddUser;

      if (success) {
        create_alert_fn(
          "success",
          "Successfully Assign User Permission.",
          () => {
            closeModal();
          }
        );
      }
    } catch (err) {
      create_alert_fn("warning", "Assign User to Project", () => {});
    } finally {
      setDubleClick(true);
    }
  };

  const openSubmitModal = () => {
    if (dubleClick) return;
    setDubleClick(false);

    create_alert_fn(
      "info",
      "Are you sure you want to create a line item?",
      async () => {
        await addUsertoMenu();
      }
    );
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="menu-add-user-modal-total-body">
        <div className="menu-add-user-modal-header-box">
          <div className="menu-add-user-modal-header-title">
            Assign User to Menu
          </div>
          <IconButton
            onClick={closeModal}
            className="menu-add-user-modal-close-btn"
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </div>

        <div className="menu-add-user-modal-main-box">
          <SelectDataList
            key={table_data}
            leftInitialData={table_data}
            readSelectItems={readSelectItems}
            setReadSelectItems={setReadSelectItems}
            editSelectItems={editSelectItems}
            setEditSelectItems={setEditSelectItems}
          />
        </div>

        <div className="menu-add-user-modal-btn-box">
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={closeModal}
            className="menu-add-user-modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={openSubmitModal}
            className="menu-add-user-modal-update-btn"
            disabled={button_disabled}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MenuAdduserModal;

const query_fetch_data = async (menu_url, menu_name) => {
  const result = { table_data: [] };

  try {
    if (!menu_url || !menu_name) {
      return;
    }

    const requestUserList = await userPermAxios.get(
      `/get-menu-perm-add-user-list/${menu_url}/${menu_name}`
    );

    const { success, data } = requestUserList;

    if (success) {
      result.table_data = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};
