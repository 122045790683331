import {
  sales_columns,
  sales_lineitems_columns,
  material_master_column,
  purchase_request_column,
  purchase_request_lineitems_column,
  request_for_quotation_column,
  request_for_quotation_lineitems_column,
  vendor_columns,
  customer_columns,
  purchase_order_column,
  purchase_order_lineitems_column,
  packing_list_column,
  packing_list_lineitems_column,
  material_receive_column,
  material_receive_lineitems_column,
  material_release_column,
  material_release_lineitems_column,
  customer_lineitem_columns,
  job_number_column,
  product_export_column,
  product_export_lineitems_column,
  vendor_lineitems_columns,
  inventory_quantity_control_column,
  inventory_quantity_control_lineitems_column,
} from "@components/materialTable/Columns";

export const createTableColumn = (
  tablename,
  query_key,
  approval_user = false,
  admin_user = false,
  user_perm = "read"
) => {
  if (tablename === "sales") {
    return sales_columns(
      tablename,
      query_key,
      approval_user,
      admin_user,
      user_perm
    );
  }

  if (tablename === "material_master") {
    return material_master_column(tablename, query_key, approval_user);
  }

  if (tablename === "job_number") {
    return job_number_column(tablename, query_key, approval_user);
  }

  if (tablename === "purchase_request") {
    return purchase_request_column(tablename, query_key, approval_user);
  }

  if (tablename === "request_for_quotation") {
    return request_for_quotation_column(tablename, query_key, approval_user);
  }

  if (tablename === "vendor") {
    return vendor_columns(tablename, query_key, approval_user);
  }

  if (tablename === "customer") {
    return customer_columns(tablename, query_key, approval_user);
  }

  if (tablename === "purchase_order") {
    return purchase_order_column(tablename, query_key, approval_user);
  }

  if (tablename === "packing_list") {
    return packing_list_column(tablename, query_key, approval_user);
  }

  if (tablename === "material_receive") {
    return material_receive_column(tablename, query_key, approval_user);
  }

  if (tablename === "material_release") {
    return material_release_column(tablename, query_key, approval_user);
  }

  if (tablename === "product_export") {
    return product_export_column(tablename, query_key, approval_user);
  }

  if (tablename === "inventory_quantity_control") {
    return inventory_quantity_control_column(
      tablename,
      query_key,
      approval_user
    );
  }

  return [];
};

export const createTableLineColumn = (tablename, query_key) => {
  const line_table_name = `${tablename}_lineitems`;

  if (tablename === "sales") {
    return sales_lineitems_columns(line_table_name, query_key);
  }

  if (tablename === "purchase_request") {
    return purchase_request_lineitems_column(line_table_name, query_key);
  }

  if (tablename === "request_for_quotation") {
    return request_for_quotation_lineitems_column(line_table_name, query_key);
  }

  if (tablename === "purchase_order") {
    return purchase_order_lineitems_column(line_table_name, query_key);
  }

  if (tablename === "packing_list") {
    return packing_list_lineitems_column(line_table_name, query_key);
  }

  if (tablename === "material_receive") {
    return material_receive_lineitems_column(line_table_name, query_key);
  }

  if (tablename === "material_release") {
    return material_release_lineitems_column(line_table_name, query_key);
  }

  if (tablename === "customer") {
    return customer_lineitem_columns();
  }

  if (tablename === "product_export") {
    return product_export_lineitems_column(line_table_name, query_key);
  }

  if (tablename === "vendor") {
    return vendor_lineitems_columns();
  }

  if (tablename === "inventory_quantity_control") {
    return inventory_quantity_control_lineitems_column(
      line_table_name,
      query_key
    );
  }

  return [];
};

export const tableStatusField = (tablename) => {
  if (tablename === "sales") {
    return { status_field: "sales_stage", approved_value: "On Bid" };
  }
  if (tablename === "vendor") {
    return { status_field: "status", approved_value: "Approved" };
  }
  if (tablename === "customer") {
    return { status_field: "status", approved_value: "Approved" };
  }
  if (tablename === "purchase_request") {
    return { status_field: "status", approved_value: "Approved" };
  }
  if (tablename === "request_for_quotation") {
    return { status_field: "status", approved_value: "Approved" };
  }
  if (tablename === "purchase_order") {
    return { status_field: "status", approved_value: "Approved" };
  }
  if (tablename === "packing_list") {
    return { status_field: "status", approved_value: "Approved" };
  }
  if (tablename === "material_receive") {
    return { status_field: "status", approved_value: "Inspected" };
  }
  if (tablename === "material_release") {
    return { status_field: "status", approved_value: "Approved" };
  }
  if (tablename === "product_export") {
    return { status_field: "status", approved_value: "Approved" };
  }
  if (tablename === "inventory_quantity_control") {
    return { status_field: "status", approved_value: "Approved" };
  }

  return false;
};

export const tableLockStatusField = (tablename) => {
  if (tablename === "sales") {
    return {
      status_field: "sales_stage",
      lock_status_list: ["On Bid", "Won", "Lost"],
    };
  }
  if (tablename === "vendor") {
    return {
      status_field: "status",
      lock_status_list: ["Approved", "Rejected"],
    };
  }
  if (tablename === "customer") {
    return {
      status_field: "status",
      lock_status_list: ["Approved", "Rejected"],
    };
  }
  if (tablename === "purchase_request") {
    return {
      status_field: "status",
      lock_status_list: ["Approved", "Rejected"],
    };
  }

  if (tablename === "request_for_quotation") {
    return {
      status_field: "status",
      lock_status_list: ["Approved", "Rejected"],
    };
  }
  if (tablename === "purchase_order") {
    return {
      status_field: "status",
      lock_status_list: ["Approved", "Rejected"],
    };
  }
  if (tablename === "packing_list") {
    return {
      status_field: "status",
      lock_status_list: ["Approved", "Rejected"],
    };
  }
  if (tablename === "material_receive") {
    return {
      status_field: "status",
      lock_status_list: ["Inspected", "Return"],
    };
  }
  if (tablename === "material_release") {
    return {
      status_field: "status",
      lock_status_list: ["Approved", "Rejected"],
    };
  }
  if (tablename === "product_export") {
    return {
      status_field: "status",
      lock_status_list: ["Approved", "Rejected"],
    };
  }
  if (tablename === "inventory_quantity_control") {
    return {
      status_field: "status",
      lock_status_list: ["Approved", "Rejected"],
    };
  }
};

export const materialTablePinnedColumn = (tablename) => {
  if (tablename === "sales") {
    return ["__check__", "data_consolidation_cell", "lead"];
  }

  if (tablename === "material_master") {
    return ["mat_category", "mat_code"];
  }

  if (tablename === "vendor") {
    return ["__detail_panel_toggle__", "__check__", "vendor_name"];
  }

  if (tablename === "purchase_request") {
    return [
      "__detail_panel_toggle__",
      "__check__",
      "data_consolidation_cell",
      "pr_no",
    ];
  }

  if (tablename === "request_for_quotation") {
    return [
      "__detail_panel_toggle__",
      "__check__",
      "data_consolidation_cell",
      "rfq_no",
    ];
  }

  if (tablename === "purchase_order") {
    return [
      "__detail_panel_toggle__",
      "__check__",
      "data_consolidation_cell",
      "po_no",
    ];
  }

  if (tablename === "packing_list") {
    return [
      "__detail_panel_toggle__",
      "__check__",
      "data_consolidation_cell",
      "qr_code_create_cell",
      "qr_code",
      "pl_no",
    ];
  }

  if (tablename === "material_receive") {
    return [
      "__detail_panel_toggle__",
      "__check__",
      "data_consolidation_cell",
      "mr_no",
    ];
  }

  if (tablename === "material_release") {
    return [
      "__detail_panel_toggle__",
      "__check__",
      "data_consolidation_cell",
      "mr_no",
    ];
  }

  if (tablename === "inventory_quantity_control") {
    return [
      "__detail_panel_toggle__",
      "__check__",
      "data_consolidation_cell",
      "iqc_no",
    ];
  }

  return [];
};

export const materialTableSearchItems = (tablename, value) => {
  let result = [];

  if (tablename === "sales") {
    result = sales_columns("", "", false);
  }

  if (tablename === "job_number") {
    result = job_number_column("", "", false);
  }

  if (tablename === "material_master") {
    result = material_master_column("", "", false);
  }

  if (tablename === "vendor") {
    result = vendor_columns("", "", false);
  }

  if (tablename === "purchase_request") {
    result = purchase_request_column("", "", false);
  }

  if (tablename === "request_for_quotation") {
    result = request_for_quotation_column("", "", false);
  }

  if (tablename === "purchase_order") {
    result = purchase_order_column("", "", false);
  }

  if (tablename === "packing_list") {
    result = packing_list_column("", "", false);
  }

  if (tablename === "material_receive") {
    result = material_receive_column("", "", false);
  }

  if (tablename === "material_release") {
    result = material_release_column("", "", false);
  }

  if (tablename === "inventory_quantity_control") {
    result = inventory_quantity_control_column("", "", false);
  }

  return result
    .filter((c) => {
      const { field, type } = c;
      if (
        (field ?? "").includes("date") ||
        (field ?? "").includes("time") ||
        type === "number"
      ) {
        return false;
      }

      const filter_arr = [
        "__detail_panel_toggle__",
        "__check__",
        "data_consolidation_cell",
      ];

      if (filter_arr.includes(field)) {
        return false;
      }

      return true;
    })
    .map((c, i) => {
      const { field } = c;

      return { id: i, field, operator: "contains", value };
    });
};
