import React from "react";
import { Navigate } from "react-router-dom";
import { useQuery } from "react-query";
import { isLoginCheck } from "@/utils/isLoginCheck";
import "./loginPageBody.scss";

const LoginPageBody = ({ children }) => {
  const query_login_check = useQuery(
    ["user_login_check", "login"],
    async () => await isLoginCheck()
  );

  const { data, isLoading } = query_login_check;

  if (!isLoading) {
    const { isLoggedIn } = data;

    if (isLoggedIn) {
      return <Navigate to="/home" />;
    }
  }

  return (
    <div className="login-page-container-body">
      <div className="login-page-container-top">
        <div className="login-page-container-top-logo">
          <img src="/logo/logo-plant.png" alt="plant-module-logo" />
        </div>
        <div className="login-page-container-top-contents-box">{children}</div>
      </div>
      <div className="login-page-container-bottom">
        <div className="login-page-container-bottom-contents">
          <div>Terms</div>
          <div className="login-page-container-bottom-contents-stick" />
          <div>Plans</div>
          <div className="login-page-container-bottom-contents-stick" />
          <div>Contact Us</div>
        </div>
        <div className="login-page-container-bottom-contents">
          Copyright © 2024 Plant Module Co.,Ltd. All rights reserved by Plant
          Module Co.,Ltd
        </div>
      </div>
    </div>
  );
};

export default LoginPageBody;
