const changeGlobalPhoneNumber = (phoneNumber) => {
  let cleanNumber = phoneNumber.replace(/-/g, "");
  // 한국 휴대폰 번호 변환
  if (cleanNumber.startsWith("010")) {
    return "+82 " + cleanNumber.substring(1);
  }

  // 미국 휴대폰 번호 변환 (미국 번호는 10자리)
  if (cleanNumber.length === 10) {
    return (
      "+1 " +
      cleanNumber.slice(0, 3) +
      "-" +
      cleanNumber.slice(3, 6) +
      "-" +
      cleanNumber.slice(6)
    );
  }
  // 태국 휴대폰 번호 변환 (태국 번호는 보통 9자리)
  if (cleanNumber.startsWith("08") && cleanNumber.length === 9) {
    return (
      "+66 " +
      cleanNumber.slice(0, 2) +
      "-" +
      cleanNumber.slice(2, 5) +
      "-" +
      cleanNumber.slice(5)
    );
  }

  return phoneNumber;
};

export default changeGlobalPhoneNumber;
