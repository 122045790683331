import Swal from "sweetalert2";

export const create_alert_fn = async (
  type,
  message,
  callback_fn = () => {}
) => {
  const alert_type = {
    icon: "success",
    title: "Success",
  };

  if (type === "warning") {
    alert_type.icon = "warning";
    alert_type.title = "Warning";
  }

  if (type === "error") {
    alert_type.icon = "error";
    alert_type.title = "Error";
  }

  if (type === "info") {
    alert_type.icon = "info";
    alert_type.title = "Information";
  }

  const { isConfirmed } = await Swal.fire({
    ...alert_type,
    text: message,
    confirmButtonText: "Confirm",
    allowOutsideClick: false,
    didOpen: () => {
      const modal = Swal.getPopup();
      modal.addEventListener("keydown", (e) => {
        e.stopPropagation();
      });
    },
  });

  if (isConfirmed && callback_fn) {
    return callback_fn();
  }
};

export const success_alert = async (message, callback_fn) => {
  return Swal.fire({
    icon: "success",
    title: "Success",
    text: message,
    allowOutsideClick: false,
  }).then((res) => {
    if (res.isConfirmed) {
      return callback_fn();
    }
  });
};

export const fail_alert = async (message, callback_fn) => {
  return Swal.fire({
    icon: "warning",
    title: "Warning",
    text: message,
    allowOutsideClick: false,
  }).then((res) => {
    if (res.isConfirmed) {
      return callback_fn();
    }
  });
};

export const error_alert = async (message, callback_fn) => {
  return Swal.fire({
    icon: "error",
    title: "Error",
    text: message,
    allowOutsideClick: false,
  }).then((res) => {
    if (res.isConfirmed) {
      return callback_fn();
    }
  });
};
