import React, { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { UserSetting } from "@atoms/userInfo";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Tooltip from "@mui/material/Tooltip";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import { IconButton, Button } from "@mui/material";

import EditorForm from "./EditorForm";
import ReCommentEidtorForm from "./ReCommentEidtorForm";
import ReCommentCard from "./ReCommentCard";

import userTimeZone from "@utils/userTimeZone";
import { userPermAxios } from "@utils/customAxios";
import useRefreshReactQuery from "@hooks/useRefreshReactQuery";
import FileDownloadBtn from "@components/FileDownloadBtn";

dayjs.extend(utc);
dayjs.extend(timezone);

const CommentCard = ({ data, targetComment, table_refresh_fn }) => {
  const userSetting = useRecoilValue(UserSetting);

  const { userId, admin_user } = userSetting ?? {};

  const [refresh] = useRefreshReactQuery([
    "comment_data",
    targetComment.parent_table_name,
    targetComment.table_name,
    targetComment.id,
  ]);

  const {
    id,
    authorId,
    body,
    created_at: createdAt,
    author_username,
    author_lang,
    comment_attachment,
    recomment_list,
  } = data;

  const [viewMode, setViewMode] = useState(true);
  const [reCommentMode, setReCommentMode] = useState(false);

  const todayDiff = dayjs()
    .utcOffset(userSetting.timeZone, false)
    .diff(dayjs(createdAt).utcOffset(userSetting.timeZone, false), "days");

  const deleteComment = async () => {
    try {
      const reqeustDeleteComment = await userPermAxios.post(
        "/delete-comments",
        {
          commentId: id,
          commentableId: targetComment.id,
          commentableType: targetComment.table_name,
        }
      );

      const { success } = reqeustDeleteComment;

      if (success) {
        refresh();
        table_refresh_fn();
      }
    } catch (err) {}
  };

  const editBtnBool = useMemo(() => {
    if (admin_user) {
      return true;
    }

    if (authorId === userId) {
      return true;
    }

    return false;
  }, [admin_user, authorId, userId]);

  return (
    <div className="comment-card-total-body">
      <div className="comment-card-total-author-box">
        <div className="comment-card-total-author">{author_username}</div>
        <div className="comment-card-total-author-control-box">
          <Tooltip
            title={dayjs(createdAt)
              .utcOffset(userSetting.timeZone, false)
              .format(userSetting.timeFormat)}
            placement="top"
            sx={{
              fontSize: 14,
              zIndex: 9999,
            }}
          >
            <IconButton>
              <AccessTimeOutlinedIcon fontSize="small" />
              <div style={{ marginLeft: 5 }}>{todayDiff}d</div>
            </IconButton>
          </Tooltip>

          {editBtnBool && (
            <>
              <Tooltip
                title={viewMode ? "Comment Modified" : "Editor Closed"}
                placement="top"
                arrow
                sx={{
                  fontSize: 14,
                }}
              >
                <IconButton onClick={() => setViewMode((prev) => !prev)}>
                  {viewMode ? (
                    <CreateOutlinedIcon fontSize="small" />
                  ) : (
                    <CloseOutlinedIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Comment Delete"
                placement="top"
                sx={{
                  fontSize: 14,
                }}
              >
                <IconButton onClick={deleteComment}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      {viewMode ? (
        <div
          className="comment-card-contents-box"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      ) : (
        <EditorForm
          targetComment={targetComment}
          initial_value={body}
          type="modified"
          comment_id={id}
          setViewMode={setViewMode}
          table_refresh_fn={table_refresh_fn}
        />
      )}

      {comment_attachment.length > 0 && (
        <>
          {/* <div className="comment-horizontal-line" /> */}

          <div className="comment-card-attach-list-box">
            {comment_attachment.map((c, i) => {
              return (
                <FileDownloadBtn url={c.url} filename={c.filename} key={i} />
              );
            })}
          </div>
        </>
      )}

      <Button
        variant="outlined"
        sx={{
          fontSize: 14,
          textTransform: "none",
          width: "100%",
          borderColor: "#ccc",
          color: "#101828",
        }}
        startIcon={<AddCommentOutlinedIcon fontSize="small" />}
        onClick={() => {
          setReCommentMode((prev) => !prev);
        }}
      >
        Post a comment
      </Button>

      {recomment_list && (
        <div className="re-comment-list-box">
          {recomment_list.map((c, i) => {
            return (
              <ReCommentCard
                key={c.id}
                target_id={c.id}
                author_id={c.author_id}
                author_name={c.author_username}
                user_id={userId}
                create_time={c.created_at}
                refresh_fn={refresh}
                today_diff={todayDiff}
                comment_attachment={c.attachments ?? []}
                parent_comment_id={id}
                table_name={targetComment.table_name}
                row_id={targetComment.id}
                value={c.body}
                table_refresh_fn={table_refresh_fn}
              />
            );
          })}
        </div>
      )}

      {reCommentMode && (
        <ReCommentEidtorForm
          initial_value=""
          parent_comment_id={id}
          table_name={targetComment.table_name}
          row_id={targetComment.id}
          type="create"
          setEditorMode={setReCommentMode}
          refresh_fn={refresh}
          table_refresh_fn={table_refresh_fn}
        />
      )}
    </div>
  );
};

export default CommentCard;
