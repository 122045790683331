import React from "react";
import ReactLoading from "react-loading";
import "@styles/components.scss";

const LoadingModal = ({ msg }) => {
  return (
    <div className="loading-modal-total-body">
      <div className="loading-modal-total-box">
        <div className="loading-modal-box">
          <ReactLoading
            type="spinningBubbles"
            color="black"
            width={56}
            height={56}
          />
          <div className="loading-modal-msg-box">
            <div className="loading-modal-title">Loading</div>
            {msg && <div className="loading-modal-detail-msg">{msg}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingModal;
