import { atom } from "recoil";

export const TargetComment = atom({
  key: "TargetComment",
  default: null,
  // default: {
  //   query_key: ["table_data", ["sales"]],
  //   table_name: "sales",
  //   field: "lead",
  //   value: "출고건1 ",
  //   id: 1,
  // },
});
