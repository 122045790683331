import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { X } from "@mui/icons-material";

const BulletChart = ({ chart_id, width, height, chart_data }) => {
  useEffect(() => {
    const {
      total_order_qnty_request = 0,
      total_order_qnty_order = 0,
      total_receive_qnty = 0,
    } = chart_data;

    if (chart_id) {
      let root = am5.Root.new(chart_id);

      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)]);

      let container = root.container.children.push(
        am5.Container.new(root, {
          width: am5.p100,
          height: am5.p100,
          layout: root.verticalLayout,
        })
      );

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/

      let chartContainer = container.children.push(
        am5.Container.new(root, {
          width: am5.p100,
          height: height - 60,
          layout: root.verticalLayout,
        })
      );

      let chart = chartContainer.children.push(
        am5xy.XYChart.new(root, {
          arrangeTooltips: false,
          // layout: root.verticalLayout,
        })
      );

      let legendContainer = container.children.push(
        am5.Container.new(root, {
          width: am5.p100,
          height: 60,
          layout: root.horizontalLayout,
          // verticalScrollbar: am5.Scrollbar.new(root, {}),
        })
      );

      chart.zoomOutButton.set("forceHidden", true);

      let tooltip = am5.Tooltip.new(root, {});

      chart.plotContainer.set("tooltipPosition", "pointer");
      chart.plotContainer.set("tooltipText", "a");
      chart.plotContainer.set("tooltip", tooltip);

      tooltip.label.adapters.add("text", function (text, target) {
        text = `[bold]Order/Request: ${returnZeroIfInvalid(
          total_order_qnty_order,
          total_order_qnty_request
        )}% \n[bold]Receive/Request: ${returnZeroIfInvalid(
          total_receive_qnty,
          total_order_qnty_request
        )}%`;

        return text;
      });

      tooltip.get("background").setAll({
        stroke: am5.color(0x000000),
        strokeOpacity: 0.8,
        fill: am5.color(0xffffff),
        fillOpacity: 0.8,
      });

      let yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "category",
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );

      yAxis.data.setAll([{ category: "category" }]);

      yAxis.get("renderer").labels.template.setAll({
        forceHidden: true,
      });

      let xRenderer = am5xy.AxisRendererX.new(root, {});

      xRenderer.grid.template.setAll({ forceHidden: true });

      xRenderer.labels.template.setAll({
        fontSize: "14px",
        fontFamily: "Inter",
      });

      let xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: xRenderer,
          min: 0,
          max: 100,
          numberFormat: "#",
        })
      );

      // Add range
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,

          valueXField: "Receive",
          categoryYField: "category",
          fill: "#F4FEFF",
          stroke: "#F4FEFF",
        })
      );

      series.columns.template.setAll({
        height: am5.p50,
      });

      series.data.setAll([
        {
          category: "category",
          Receive: Number(
            returnZeroIfInvalid(total_receive_qnty, total_order_qnty_request)
          ),
        },
      ]);

      [
        { value: 100, color: "#F79009" },
        {
          value: Number(
            returnZeroIfInvalid(
              total_order_qnty_order,
              total_order_qnty_request
            )
          ),
          color: "#0080DB",
        },
      ].forEach((c) => {
        const { value, color } = c;

        var rangeDataItem = xAxis.makeDataItem({
          value: 0,
          endValue: value,
        });

        var range = xAxis.createAxisRange(rangeDataItem);

        rangeDataItem.get("axisFill").setAll({
          visible: true,
          fill: color,
          stroke: color,
          fillOpacity: 1,
        });
      });

      // blue;
      // #0080DB

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      let cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          behavior: "none",
        })
      );
      cursor.lineY.set("visible", false);
      cursor.lineX.set("visible", false);

      let legend = legendContainer.children.push(
        am5.Legend.new(root, {
          nameField: "name",
          fillField: "color",
          strokeField: "stroke",

          centerX: am5.percent(50),
          x: am5.percent(50),
        })
      );

      legend.labels.template.setAll({
        fontSize: "14px",
        fontFamily: "Inter",
        wrap: false,
      });

      legend.data.setAll([
        {
          name: "Receive",
          color: "#F4FEFF",
          stroke: "#344054",
        },
        {
          name: "Order",
          color: "#0080DB",
          stroke: "#0080DB",
        },
        {
          name: "Request",
          color: "#F79009",
          stroke: "#F79009",
        },
      ]);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);

      series.appear();

      return () => {
        root && root.dispose();
      };
    }
  }, [chart_id, chart_data]);

  if (!chart_id) {
    return null;
  }

  return <div id={chart_id} style={{ width, height }} />;
};

export default BulletChart;

const returnZeroIfInvalid = (num, num2) => {
  const value = (num / num2) * 100;

  if (value === Infinity || isNaN(value) || value === false) {
    return 0;
  }

  return value.toFixed(2);
};
