import React, { useMemo } from "react";
import {
  Modal,
  IconButton,
  Button,
  InputLabel,
  TextField,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { labelInputTheme } from "@styles/muiThemes";
import "@styles/components.scss";

const AddModal = ({ addModalStatus, onClose, onChange, onSubmit }) => {
  const { open, contact_name, phone, email, job_name } = addModalStatus;

  const button_disabled = useMemo(() => {
    return ![contact_name, phone, email, job_name]
      .map((c) => {
        return c.replaceAll(" ", "");
      })
      .every((c) => c.length > 0);
  }, [contact_name, phone, email, job_name]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="contact-point-add-modal-total-body"
        style={{ width: 720 }}
      >
        <div className="contact-point-add-modal-main-box">
          <div className="contact-point-add-modal-main">
            <LabelInput
              label={"Name"}
              value={contact_name}
              field={"contact_name"}
              onChange={onChange}
            />
            <LabelInput
              label={"Email"}
              value={email}
              field={"email"}
              onChange={onChange}
            />
            <LabelInput
              label={"Phone"}
              value={phone}
              field={"phone"}
              onChange={onChange}
            />
            <LabelInput
              label={"Job Name"}
              value={job_name}
              field={"job_name"}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="contact-point-add-modal-btn-box">
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={onClose}
            className="contact-point-add-modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            className="contact-point-add-modal-update-btn"
            disabled={button_disabled}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddModal;

const LabelInput = ({
  label,
  value,
  onChange,
  field,
  helperText,
  placeholder,
  error,
  disabled = false,
}) => {
  return (
    <div className="label-input-box">
      <ThemeProvider theme={labelInputTheme}>
        <InputLabel
          htmlFor={`label-input-${label}`}
          className="label-input-title"
        >
          {label}
        </InputLabel>
        <TextField
          id={`label-input-${label}`}
          value={value}
          onChange={(e) => onChange(e.target.value, field)}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          disabled={disabled}
        />
      </ThemeProvider>
    </div>
  );
};
