import { useCallback } from "react";
import { useQueryClient } from "react-query";

const useRefreshReactQuery = (query_key) => {
  const queryClient = useQueryClient();

  const refresh = useCallback(() => {
    queryClient.invalidateQueries(query_key);
    // eslint-disable-next-line
  }, [query_key]);

  return [refresh];
};

export default useRefreshReactQuery;
