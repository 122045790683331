import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const YearPicker = ({ value, onChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CustomInputDatePicker value={value} onChange={onChange} />
    </LocalizationProvider>
  );
};

export default YearPicker;

const InputField = (props) => {
  const {
    open,
    setOpen,
    label,
    id,
    disabled,
    value,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  const yearValue = dayjs(value).format("YYYY");

  return (
    <div
      className="year-picker-input-body"
      id={id}
      ref={ref}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      <CalendarIcon />

      <div className="year-picker-input-value">{yearValue}</div>

      <div
        className="year-picker-input-btn-box"
        style={{
          rotate: open ? "180deg" : "360deg",
        }}
      >
        <UpDownIcon />
      </div>
    </div>
  );
};

const CustomInputDatePicker = (props) => {
  const { value, onChange } = props;
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      slots={{ ...props.slots, field: InputField }}
      slotProps={{ ...props.slotProps, field: { setOpen, open } }}
      {...props}
      views={["year"]}
      format="YYYY"
      open={open}
      onChange={onChange}
      value={value}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
};

const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.78125 2.5C6.04013 2.5 6.25 2.70987 6.25 2.96875V3.4375H13.75V2.96875C13.75 2.70987 13.9599 2.5 14.2188 2.5C14.4776 2.5 14.6875 2.70987 14.6875 2.96875V3.4375H15.625C16.6605 3.4375 17.5 4.27697 17.5 5.3125V15.625C17.5 16.6605 16.6605 17.5 15.625 17.5H4.375C3.33947 17.5 2.5 16.6605 2.5 15.625V5.3125C2.5 4.27697 3.33947 3.4375 4.375 3.4375H5.3125V2.96875C5.3125 2.70987 5.52237 2.5 5.78125 2.5ZM3.4375 6.25V15.625C3.4375 16.1428 3.85723 16.5625 4.375 16.5625H15.625C16.1428 16.5625 16.5625 16.1428 16.5625 15.625V6.25H3.4375Z"
        fill="#344054"
      />
    </svg>
  );
};

const UpDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.4858 7.33714C14.7469 7.59826 14.7469 8.01926 14.4858 8.28038L10.0573 12.7088C9.84951 12.9167 9.51377 12.9167 9.30594 12.7088L4.87748 8.28038C4.61636 8.01926 4.61636 7.59826 4.87748 7.33714C5.13861 7.07601 5.55961 7.07601 5.82073 7.33714L9.68431 11.1954L13.5479 7.33181C13.8037 7.07602 14.23 7.07601 14.4858 7.33714Z"
        fill="#344054"
      />
    </svg>
  );
};
