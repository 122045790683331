import React, { useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const ContactPointTable = ({ title, rows = [], columns = [] }) => {
  return (
    <div className="contact-point-table-total-body">
      <div className="contact-point-table-title-total-box">
        <div className="contact-point-table-title-box">
          <Box
            tabIndex={-1}
            // onClick={() => setTableToggle((prev) => !prev)}
          >
            <ExpandMoreIcon
              sx={{
                transform: `rotateZ(360deg)`,
                transition: (theme) =>
                  theme.transitions.create("transform", {
                    duration: theme.transitions.duration.shortest,
                  }),
                width: "24px",
                height: "24px",
              }}
            />
          </Box>

          <div className="contact-point-table-title">{title}</div>
        </div>
      </div>
      <div className="contact-point-table-table-total-box">
        <div className="contact-point-table-table-box" style={{ height: 168 }}>
          <DataGridPro
            rows={rows}
            columns={columns}
            rowHeight={28}
            columnHeaderHeight={28}
            sx={{
              ...materialTableStyle,
              ...TableScrollbarStyle,
            }}
            hideFooter
            disableColumnPinning
            disableColumnSelector
            disableDensitySelector
          />
        </div>
      </div>
    </div>
  );
};

export default ContactPointTable;
