// export const createGeneralMenu = () => [
//   {
//     id: "Sales",
//     label: "Sales",
//     fileType: "folder",
//     children: [
//       {
//         id: "pipeline",
//         label: "Sales Pipeline Management",
//         path: "/home/sales",
//         fileType: "",
//       },
//     ],
//   },
//   {
//     id: "Company",
//     label: "Company",
//     fileType: "folder",
//     children: [
//       {
//         id: "jn",
//         label: "Job Number List",
//         path: "/home/job_number",
//         fileType: "",
//       },
//       {
//         id: "mm",
//         label: "Material Master",
//         path: "/home/material_master",
//         fileType: "",
//       },
//       {
//         id: "ve",
//         label: "Vendor List",
//         path: "/home/vendor",
//         fileType: "",
//       },
//       {
//         id: "cu",
//         label: "Customer List",
//         path: "/home/customer",
//         fileType: "",
//       },
//     ],
//   },
//   {
//     id: "Material",
//     label: "Material",
//     fileType: "folder",
//     children: [
//       {
//         id: "pr",
//         label: "Purchase Requisition",
//         path: "/home/purchase_request",
//         fileType: "",
//       },
//       {
//         id: "rfq",
//         label: "Request for Quotation",
//         path: "/home/request_for_quotation",
//         fileType: "",
//       },
//       {
//         id: "po",
//         label: "Purchase Order",
//         path: "/home/purchase_order",
//         fileType: "",
//       },
//       {
//         id: "pl",
//         label: "Packing List",
//         path: "/home/packing_list",
//         fileType: "",
//       },
//       {
//         id: "in",
//         label: "Material Received",
//         path: "/home/material_receive",
//         fileType: "",
//       },
//       {
//         id: "out",
//         label: "Material Release",
//         path: "/home/material_release",
//         fileType: "",
//       },
//       {
//         id: "result",
//         label: "Material Inventory",
//         path: "/home/material_inventory",
//         fileType: "",
//       },
//       {
//         id: "product_export",
//         label: "Product Export",
//         path: "/home/product_export",
//         fileType: "",
//       },
//     ],
//   },
// ];

export const createAdminMenu = () => [
  {
    id: "UserAdministration",
    label: "User Administration",
    fileType: "folder",
    children: [
      {
        id: "ur",
        label: "User Register & Assign",
        path: "/admin/user_registration",
        fileType: "",
      },
      {
        id: "autb",
        label: "Assign User to BPs",
        path: "/admin/assign_user_to_bps",
        fileType: "",
      },
      {
        id: "autp",
        label: "Assign User to Project",
        path: "/admin/assign_user_to_project",
        fileType: "",
      },
    ],
  },
  {
    id: "logs",
    label: "Logs",
    fileType: "folder",
    children: [
      {
        id: "loginlog",
        label: "Login Activity Logs",
        path: "/admin/login_log",
        fileType: "",
      },
      // {
      //   id: "recordlog",
      //   label: "Record Activity Logs",
      //   path: "/admin/record_log",
      //   fileType: "",
      // },
    ],
  },
  {
    id: "rts",
    label: "Reports",
    fileType: "folder",
    children: [
      {
        id: "tax_report",
        label: "Tax Report",
        path: "/admin/tax_report",
        fileType: "",
      },
    ],
  },
];

export const permission_menu_list = [
  {
    id: 1,
    top_menu: "Sales",
    menu_name: "Sales Pipeline Management",
    menu_column_name: "sales",
  },
  {
    id: 2,
    top_menu: "Company",
    menu_name: "Material Master",
    menu_column_name: "material_master",
  },
  {
    id: 3,
    top_menu: "Company",
    menu_name: "Vendor List",
    menu_column_name: "vendor",
  },
  {
    id: 4,
    top_menu: "Material",
    menu_name: "Purchase Requisition",
    menu_column_name: "purchase_request",
  },
  {
    id: 5,
    top_menu: "Material",
    menu_name: "Purchase Order",
    menu_column_name: "purchase_order",
  },
  {
    id: 6,
    top_menu: "Material",
    menu_name: "Packing List",
    menu_column_name: "packing_list",
  },
  {
    id: 7,
    top_menu: "Material",
    menu_name: "Material Received",
    menu_column_name: "material_receive",
  },
  {
    id: 8,
    top_menu: "Material",
    menu_name: "Material Release",
    menu_column_name: "material_release",
  },
  {
    id: 9,
    top_menu: "Material",
    menu_name: "Material Inventory",
    menu_column_name: "material_inventory",
  },
  {
    id: 10,
    top_menu: "Company",
    menu_name: "Customer List",
    menu_column_name: "customer",
  },
  {
    id: 11,
    top_menu: "Material",
    menu_name: "Product Export",
    menu_column_name: "product_export",
  },
  {
    id: 12,
    top_menu: "Material",
    menu_name: "Inventory Quantity Control",
    menu_column_name: "inventory_quantity_control",
  },

  // { id: 10, menu_name: "", menu_column_name: "" },
  // { id: 11, menu_name: "", menu_column_name: "" },
  // { id: 12, menu_name: "", menu_column_name: "" },
  // { id: 13, menu_name: "", menu_column_name: "" },
  // { id: 14, menu_name: "", menu_column_name: "" },
];
