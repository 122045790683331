import React from "react";
import LabelInput from "@components/LabelInput";
import LabelSelect from "@components/LabelSelect";
import { countries } from "@utils/userTimeZone";
import "./accountSetting.scss";

const AccountSetting = ({
  name,
  email,
  setEmail,
  phone,
  setPhone,
  country,
  setCountry,
  emailCheck,
}) => {
  return (
    <div className="account-setting-total-body">
      <LabelInput
        label="Name"
        value={name}
        setValue={() => {}}
        disabled={true}
      />
      <LabelInput
        label="Email"
        value={email}
        setValue={setEmail}
        error={emailCheck === null ? false : true}
        helperText={emailCheck}
      />
      <LabelInput label="Phone" value={phone} setValue={setPhone} />
      <LabelSelect
        label="Country"
        value={country ?? ""}
        setValue={setCountry}
        list={countries.map((c) => ({ name: c.label, value: c.label }))}
      />
    </div>
  );
};

export default AccountSetting;
