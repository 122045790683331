import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { UserTargetProject, UserSetting } from "@atoms/userInfo";
import { useQuery } from "react-query";
import _ from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import FilterCard from "@components/materialInven/FilterCard";
import InvenTable from "@components/materialInven/InvenTable";
import MaterialStatus from "@components/materialInven/MaterialStatus";
import BulletChart from "@components/charts/BulletChart";
import { material_inventory_column } from "@components/materialTable/Columns";
import { userPermAxios } from "@utils/customAxios";

dayjs.extend(utc);
dayjs.extend(timezone);

const MaterialInven = () => {
  const { menu } = useParams();
  const [dateRange, setDateRange] = useState({
    start: dayjs().add(-1, "months").utc().toDate(),
    end: dayjs.utc().toDate(),
  });
  const [category, setCategory] = useState("overall");
  const userSetting = useRecoilValue(UserSetting);
  const userTargetProject = useRecoilValue(UserTargetProject);
  const [isLoading, setIsLoading] = useState(true);

  const query_key = [
    "material_inventory",
    userTargetProject,
    dateRange,
    category,
  ];

  const query_table_data = useQuery(
    query_key,
    () => query_fetch_data(userTargetProject, dateRange, category),
    {
      initialData: { row: [], table_data: {} },
      onSuccess: () => setIsLoading(false),
    }
  );

  const { row, table_data } = query_table_data.data;

  const table_column = material_inventory_column();

  console.log(row);

  return (
    <div className="inven-memu-total-body">
      <div className="inven-memu-card-body">
        <FilterCard
          dateRange={dateRange}
          setDateRange={setDateRange}
          category={category}
          setCategory={setCategory}
        />
      </div>
      <div className="inven-memu-card-body">
        <MaterialStatus table_data={table_data} />
      </div>
      <div className="inven-memu-card-body">
        <BulletChart
          chart_id="material-inventory-bullet-chart"
          width="100%"
          height={140}
          chart_data={table_data}
          category_field="category"
          column_color="#fff"
          unit="%"
        />
      </div>

      <div className="inven-memu-card-body">
        <InvenTable isLoading={isLoading} row={row} column={table_column} />
      </div>
    </div>
  );
};

export default MaterialInven;

const query_fetch_data = async (user_project, dateRange, category) => {
  const { start, end } = dateRange;
  const result = { row: [], table_data: {} };

  if (!user_project) {
    return result;
  }

  try {
    const requestUserPerm = await userPermAxios.post("/is_logged_in");

    // eslint-disable-next-line
    const { isLoggedIn } = requestUserPerm;

    if (true) {
      const requestRowsData = await userPermAxios.post(
        `/material_inventory/${user_project?.project_code}/rows`,
        {
          start,
          end,
          category,
        }
      );

      const { data } = requestRowsData;

      const format_change = data
        .filter((c) => c.id)
        .map((c) => {
          return {
            ...c,
            id: c.id,
            total_order_qnty_request: Number(c.total_order_qnty_request),
            total_order_qnty_order: Number(c.total_order_qnty_order),
            total_receive_qnty: Number(c.total_receive_qnty),
            total_release_qnty: Number(c.total_release_qnty),
            in_stock: Number(c.in_stock),
          };
        });

      const total_order_qnty_request = _.sumBy(
        format_change,
        "total_order_qnty_request"
      );
      const total_order_qnty_order = _.sumBy(
        format_change,
        "total_order_qnty_order"
      );
      const total_receive_qnty = _.sumBy(format_change, "total_receive_qnty");
      const total_release_qnty = _.sumBy(format_change, "total_release_qnty");
      const in_stock = _.sumBy(format_change, "in_stock");

      result.row = format_change;
      result.table_data = {
        total_order_qnty_request,
        total_order_qnty_order,
        total_receive_qnty,
        total_release_qnty,
        in_stock,
      };
    }
  } catch (err) {
  } finally {
    return result;
  }
};
