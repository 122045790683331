import React, { useState, useRef, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { TargetComment } from "@atoms/commnet";
import { UserTargetProject, UserSetting } from "@atoms/userInfo";
import { useQuery } from "react-query";
import { IconButton } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import _ from "lodash";
import SelectRowControlMenu from "@components/materialTable/SelectRowControlMenu";
import UpdateComment from "@components/comment/UpdateComment";
import MaterialMasterTable from "@/components/materialMasterTable/MaterialMasterTable";
import IconInput from "@/components/IconInput";
import useRefreshReactQuery from "@hooks/useRefreshReactQuery";
import { groupColorList } from "@utils/colorList";
import {
  createTableColumn,
  materialTablePinnedColumn,
  materialTableSearchItems,
} from "@utils/tableInitialData";
import { userPermAxios } from "@utils/customAxios";
import "./menu.scss";

const MaterialMaster = () => {
  const { menu } = useParams();
  const userTargetProject = useRecoilValue(UserTargetProject);
  const userSetting = useRecoilValue(UserSetting);

  const admin_user = userSetting?.admin_user;
  const approver = userSetting?.approver;

  const [targetComment, setTargetComment] = useRecoilState(TargetComment);
  const [selectionModel, setSelectionModel] = useState(null);
  const [tableRefs, setTableRefs] = useState({});
  const [soketData, setSoketData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [groupRow, setGroupRow] = useState({});
  const webSocket = useRef(null);
  const [searchValue, setSearchValue] = useState("");

  const query_key = useMemo(
    () => ["table_data", menu, userTargetProject],
    [menu, userTargetProject]
  );

  const [refresh] = useRefreshReactQuery(query_key);

  const query_table_data = useQuery(
    query_key,
    () => query_fetch_data(menu, userTargetProject),
    {
      initialData: {
        rows: null,
        group_rows: {},
        lineitem_use: false,
        user_perm: null,
      },
      onSuccess: (data) => {
        setIsLoading(false);
        setGroupRow(data.group_rows);
      },
    }
  );

  const { lineitem_use, user_perm } = query_table_data.data;

  useEffect(() => {
    setSelectionModel(null);
  }, [userTargetProject]);

  useEffect(() => {
    setGroupRow({});
    setSelectionModel(null);
    setTableRefs(null);
    setTargetComment(null);
    setSoketData(null);
    setIsLoading(true);
    // eslint-disable-next-line
  }, [menu]);

  const selectControlMenuBool = useMemo(() => {
    if (!selectionModel) {
      return { status: false, count: 0 };
    }

    const total = [];

    for (const item in selectionModel) {
      total.push(...selectionModel[item]);
    }

    if (total.length === 0) {
      return { status: false, count: 0 };
    } else {
      return { status: true, count: total.length };
    }
  }, [selectionModel]);

  useEffect(() => {
    if (Object.keys(groupRow).length > 0) {
      const newRefs = {};

      Object.keys(groupRow).forEach((c) => {
        newRefs[c] = React.createRef();
      });

      setTableRefs(newRefs);
    }
  }, [groupRow, menu]);

  useEffect(() => {
    webSocket.current = new WebSocket(
      `${process.env.NODE_ENV === "development" ? "ws" : "wss"}://${
        process.env.REACT_APP_SOKET_URI
      }`
    );

    if (webSocket) {
      webSocket.current.onopen = () => {
        console.log("WebSocket 연결!");
      };

      webSocket.current.onclose = (error) => {};

      webSocket.current.onerror = (error) => {
        console.log("WebSocket 실패!", error);
      };

      webSocket.current.onmessage = (event) => {
        const { data } = event;

        const dataParse = JSON.parse(data);

        const { action, tablename } = dataParse;

        const {
          group_name,
          id,
          userId,
          field,
          value,
          keyName,
          keyValue,
          addRow,
        } = dataParse.data;

        const api = tableRefs[group_name]?.current?.apiRef;

        setSoketData(dataParse);

        if (menu === tablename) {
          if (action === "delete-row" || action === "update-cell-group") {
            refresh();
          }

          if (action === "new-material" && tablename === "material_master") {
            const material_api =
              tableRefs?.["Material Master"]?.current?.apiRef;
            if (material_api) {
              material_api.updateRows([{ ...addRow }]);
            }
          }
        }
      };
      return () => {
        webSocket.current?.close();
      };
    }
    // eslint-disable-next-line
  }, [webSocket, tableRefs, refresh]);

  const columns = useMemo(() => {
    const statusPerm = admin_user ? true : approver ? true : false;

    const table_column = createTableColumn(menu, query_key, statusPerm);

    return table_column.filter((c) => {
      const { field } = c;

      if (admin_user) {
        return true;
      }

      if (!user_perm || user_perm === "read") {
        if (
          field === "data_consolidation_cell" ||
          field === "qr_code_create_cell"
        ) {
          return false;
        }
      }

      return true;
    });
  }, [menu, query_key, admin_user, user_perm, approver]);

  const upperTablePinnedColumns = useMemo(() => {
    return materialTablePinnedColumn(menu);
  }, [menu]);

  const tableGroupHeight = useMemo(() => {
    if (menu === "material_master") {
      return "calc(100vh - 104px - 48px - 8px - 16px)";
    }

    return "calc(100vh - 104px - 48px - 8px - 40px - 32px - 10px)";
  }, [menu]);

  const filterItems = useMemo(() => {
    return materialTableSearchItems(menu, searchValue);
  }, [menu, searchValue]);

  return (
    <div className="memu-total-body">
      <div className="menu-button-box">
        <div className="menu-control-botton-group">
          <IconInput
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search Here"
            rightIcon={
              searchValue.length > 0 ? (
                <IconButton onClick={() => setSearchValue("")}>
                  <CloseOutlinedIcon fontSize="small" />
                </IconButton>
              ) : null
            }
          />
        </div>
        {/* <div className="menu-button-box-vertical-bar" /> */}
      </div>

      <div
        className="layout-table-group-box"
        style={{ height: tableGroupHeight }}
      >
        {groupRow &&
          Object.keys(tableRefs ?? {}).length > 0 &&
          Object.keys(groupRow ?? {})
            .reverse()
            .map((c, i) => {
              const colorIndex = i % groupColorList.length;

              return (
                <MaterialMasterTable
                  key={c}
                  user_perm={user_perm}
                  table_name={menu}
                  line_table_name={`${menu}_lineitems`}
                  rows={groupRow?.[c] ?? []}
                  columns={columns.map((c2) => ({
                    ...c2,
                    table_group_name: c,
                  }))}
                  group_list={Object.keys(groupRow)}
                  group_name={c}
                  selectionModel={selectionModel?.[c] ?? []}
                  setSelectionModel={setSelectionModel}
                  ref={tableRefs[c]}
                  soketData={soketData}
                  isLoading={isLoading}
                  lineDataUse={lineitem_use}
                  group_color={groupColorList[colorIndex]}
                  upperTablePinnedColumns={upperTablePinnedColumns}
                  filter_items={filterItems}
                />
              );
            })}
      </div>

      {selectControlMenuBool.status && (
        <SelectRowControlMenu
          table_name={menu}
          group_list={Object.keys(groupRow)}
          totalCount={selectControlMenuBool.count}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
          refs={tableRefs}
          columns={columns}
        />
      )}

      {targetComment && <UpdateComment />}
    </div>
  );
};

export default MaterialMaster;

const query_fetch_data = async (table_name, user_project) => {
  const result = {
    rows: null,
    group_rows: {},
    lineitem_use: false,
    user_perm: null,
  };

  if (!user_project) {
    return result;
  }

  try {
    const requestRowsData = await userPermAxios.get(
      `/${table_name}/${user_project?.project_code}/rows`
    );

    const { data, lineitem_use } = requestRowsData;

    const supplied_mat_data = data.map((c) => ({
      ...c,
      group_name:
        c.supplied_mat === 1
          ? "Customer Supplied Material List"
          : "Material List",
    }));

    const data_group = _.groupBy(supplied_mat_data, "group_name");

    const requestUserPermStatus = await userPermAxios.get(
      `/${table_name}/get-user-menu-perm-status`
    );

    const { permStatus } = requestUserPermStatus;

    result.group_rows = data_group;
    result.lineitem_use = lineitem_use;
    result.user_perm = permStatus;
  } catch (err) {
  } finally {
    return result;
  }
};
